body {
  color: #000;
  word-wrap: break-word;
  text-align: left;
  background: #e9eff4;
  width: 100%;
  min-width: 320px;
  max-width: 640px;
  margin: 0 auto;
  padding: 0;
  font-family: arial, sans-serif;
  font-size: 8px;
  line-height: 1.5;
}

.mimibazar-block:after, .mimibazar-block:before {
  clear: both;
  visibility: hidden;
  width: 0;
  height: 0;
  display: block;
  overflow: hidden;
}

.blesk .mimibazar-block {
  margin-bottom: 0;
}

.mimibazar-block h2 {
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 2em;
}

@media only screen and (min-width: 700px) {
  .blesk .mimibazar-block .item .title {
    height: 62px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  body {
    font-size: 10px;
  }

  .mainMenu .headerLogo {
    left: 7% !important;
  }

  .enquiryHP .firstChoice .answer, .enquiryHP .secondChoice .answer {
    clear: both;
  }

  .testClass .article-container .iconTest.icon-test_small {
    width: 49px;
    height: 45px;
    background-position: -512px -1142px !important;
  }

  .article-container.middleArticle h2.title, .article-container.middleArticle h3.title {
    width: 80%;
  }
}

@media only screen and (min-width: 480px) and (max-width: 640px) {
  body {
    font-size: 13px;
  }

  .loadMoreArt {
    margin: 20px auto !important;
  }
}

@media only screen and (min-width: 640px) {
  body {
    font-size: 15px;
  }
}

@media only screen and (orientation: portrait) {
  html {
    font-size: 3vw;
  }
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mr-0 {
  margin-right: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.m-5 {
  margin: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.m-10 {
  margin: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.m-15 {
  margin: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.m-20 {
  margin: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.m-25 {
  margin: 25px;
}

.mt-25 {
  margin-top: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.m-30 {
  margin: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.m-35 {
  margin: 35px;
}

.mt-35 {
  margin-top: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.ml-35 {
  margin-left: 35px;
}

.m-40 {
  margin: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.m-45 {
  margin: 45px;
}

.mt-45 {
  margin-top: 45px;
}

.mr-45 {
  margin-right: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.ml-45 {
  margin-left: 45px;
}

.m-50 {
  margin: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.mr-50 {
  margin-right: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.m-55 {
  margin: 55px;
}

.mt-55 {
  margin-top: 55px;
}

.mr-55 {
  margin-right: 55px;
}

.mb-55 {
  margin-bottom: 55px;
}

.ml-55 {
  margin-left: 55px;
}

.m-60 {
  margin: 60px;
}

.mt-60 {
  margin-top: 60px;
}

.mr-60 {
  margin-right: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-60 {
  margin-left: 60px;
}

.m-65 {
  margin: 65px;
}

.mt-65 {
  margin-top: 65px;
}

.mr-65 {
  margin-right: 65px;
}

.mb-65 {
  margin-bottom: 65px;
}

.ml-65 {
  margin-left: 65px;
}

.m-70 {
  margin: 70px;
}

.mt-70 {
  margin-top: 70px;
}

.mr-70 {
  margin-right: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

.ml-70 {
  margin-left: 70px;
}

.m-75 {
  margin: 75px;
}

.mt-75 {
  margin-top: 75px;
}

.mr-75 {
  margin-right: 75px;
}

.mb-75 {
  margin-bottom: 75px;
}

.ml-75 {
  margin-left: 75px;
}

.m-80 {
  margin: 80px;
}

.mt-80 {
  margin-top: 80px;
}

.mr-80 {
  margin-right: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.ml-80 {
  margin-left: 80px;
}

.m-85 {
  margin: 85px;
}

.mt-85 {
  margin-top: 85px;
}

.mr-85 {
  margin-right: 85px;
}

.mb-85 {
  margin-bottom: 85px;
}

.ml-85 {
  margin-left: 85px;
}

.m-90 {
  margin: 90px;
}

.mt-90 {
  margin-top: 90px;
}

.mr-90 {
  margin-right: 90px;
}

.mb-90 {
  margin-bottom: 90px;
}

.ml-90 {
  margin-left: 90px;
}

.m-95 {
  margin: 95px;
}

.mt-95 {
  margin-top: 95px;
}

.mr-95 {
  margin-right: 95px;
}

.mb-95 {
  margin-bottom: 95px;
}

.ml-95 {
  margin-left: 95px;
}

.m-100 {
  margin: 100px;
}

.mt-100 {
  margin-top: 100px;
}

.mr-100 {
  margin-right: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-100 {
  margin-left: 100px;
}

.mb-promobox {
  width: 100% !important;
  margin-bottom: 10px !important;
  padding: 0 !important;
}

.article:last-of-type .mb-promobox {
  margin-bottom: 0 !important;
}

html, body {
  border: 0;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

p img {
  margin: 0 10px 5px 0;
  padding: 0;
}

img {
  border: 0;
  max-width: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: auto;
}

@font-face {
  font-family: tablet_gothic_compressed;
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet-gothic-compressed-bold-18-2.eot");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet-gothic-compressed-bold-18-2.eot?#iefix") format("embedded-opentype");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet-gothic-compressed-bold-18-2.woff") format("woff");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet-gothic-compressed-bold-18-2.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: tablet_gothic_condensed;
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.eot?#iefix") format("embedded-opentype");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.woff") format("woff");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont2-18-2.ttf?v=3") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: tablet_gothic_condensed_extra;
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-extrabold-webfont.eot");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-extrabold-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-extrabold-webfont.woff") format("woff"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-extrabold-webfont.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.container {
  clear: both;
  width: 100%;
  max-width: 640px;
  margin: 0 auto 3em;
  position: relative;
}

.grid_1, .grid_2, .grid_3, .grid_4, .grid_5, .grid_6, .grid_7, .grid_8, .grid_9, .grid_10, .grid_11, .grid_12 {
  float: left;
  margin: 0;
  display: block;
}

.container .grid_1 {
  width: 12.5%;
}

.container .grid_2 {
  width: 25%;
}

.container .grid_3 {
  width: 37.5%;
}

.container .grid_4 {
  width: 50%;
}

.container .grid_5 {
  width: 62.5%;
}

.container .grid_6 {
  width: 75%;
}

.container .grid_7 {
  width: 87.5%;
}

.container .grid_8 {
  width: 100%;
}

.inner_1, .inner_2, .inner_3, .inner_4, .inner_5, .inner_6, .inner_7, .inner_8, .inner_9, .inner_10, .inner_11, .inner_12 {
  float: left;
  margin: 0 0 5px;
  padding: 5px 1.5625% 0;
  display: block;
}

.outer_1, .outer_2, .outer_3, .outer_4, .outer_5, .outer_6, .outer_7, .outer_8, .outer_9, .outer_10, .outer_11, .outer_12 {
  float: left;
  margin: 0 1.5625%;
  display: block;
}

.container .outer_1, .container .inner_1 {
  width: 9.375%;
}

.container .outer_2, .container .inner_2 {
  width: 21.875%;
}

.container .outer_3, .container .inner_3 {
  width: 34.375%;
}

.container .outer_4, .container .inner_4 {
  width: 46.875%;
}

.container .outer_5, .container .inner_5 {
  width: 59.375%;
}

.container .outer_6, .container .inner_6 {
  width: 71.875%;
}

.container .outer_7, .container .inner_7 {
  width: 84.375%;
}

.container .outer_8, .container .inner_8 {
  width: 96.875%;
}

* {
  box-sizing: content-box;
}

.clear, .clearfix {
  clear: both;
  visibility: hidden;
  width: 0;
  height: 0;
  display: block;
  overflow: hidden;
}

.clearfix:before, .clearfix:after, .cb:before, .cb:after {
  content: " ";
  visibility: hidden;
  width: 0;
  height: 0;
  display: block;
  overflow: hidden;
}

.clearfix:after, .cb:after {
  clear: both;
}

a {
  color: red;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

.footerInfo, .mainMenu {
  color: #fff;
  background: none;
}

.footerInfo {
  text-align: center;
  text-transform: uppercase;
  color: #848f9b;
  margin: 1em 0;
  padding: 5px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.6em;
  line-height: 1.2em;
}

#footer {
  clear: both;
  float: left;
  width: 100%;
  margin-top: 3em;
  margin-bottom: 3em;
}

.displayType {
  text-align: center;
  clear: both;
  color: red;
  background: none;
  padding: 0 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 2em;
  line-height: 1.5em;
}

.displayType a {
  color: red;
  padding: 0 5px;
}

.topLine {
  box-sizing: border-box;
  color: #707b87;
  width: 100%;
  min-height: 30px;
  padding: 9px 5px 0;
  font-size: 1.2em;
  line-height: 1.1;
}

.topLine .date {
  font-weight: bold;
}

.calendarContainer, .forecastContainer {
  float: left;
  width: 50%;
  position: relative;
}

.mainMenu {
  background: #e90000;
  height: 40px;
  position: relative;
  box-shadow: 0 5px 10px -4px rgba(0, 0, 0, .5);
}

.mainMenu .header-logo-wrapper {
  margin: 0;
}

.mainMenu .header-logo-wrapper .headerLogo {
  text-indent: -9999px;
  background: url("https://img2.cncenter.cz/images/blesk-2013/logo-mobile.png?v=3") no-repeat;
  width: 280px;
  height: 29px;
  margin: 0 auto;
  display: block;
  position: relative;
  top: 5px;
}

.searchForm {
  background: #e90000;
  border-top: 1px solid #e90000;
  border-bottom: 1px solid #e90000;
  width: 100%;
  height: 36px;
  margin-top: 0;
  padding: 1px 0;
  font-size: 1.3em;
  line-height: 1.2em;
  position: relative;
}

.closeMenu {
  color: #e90000;
  cursor: pointer;
  background: none;
  padding: 12px 10px;
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
}

.searchForm form {
  margin: 0;
}

.searchForm .searchF {
  text-indent: 10px;
  float: left;
  color: #707b87;
  background: #16212d;
  border: none;
  width: 88%;
  height: 36px;
  margin: 0;
  padding: 0 0%;
  font-size: 1.4em;
  font-weight: bold;
  line-height: 38px;
}

.searchForm .searchB {
  float: left;
  color: #fff;
  cursor: pointer;
  text-indent: -9999px;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp") -50px -50px no-repeat content-box content-box;
  border: none;
  width: 5%;
  min-width: 17px;
  height: 18px;
  margin: 9px 0;
  padding: 0 2%;
  font-size: 2em;
  line-height: 38px;
  position: relative;
}

.searched {
  color: #16212d;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 2em;
}

.forecastHP {
  float: right;
  width: 100%;
  height: 32px;
  margin-top: 0;
  line-height: 36px;
}

.forecastHP .forecast {
  color: #707b87;
  float: left;
  width: 50%;
  height: 38px;
  padding-top: -2px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.6em;
}

.forecastHP .forecast.today span.temperature, .forecastHP .forecast.tomorrow span.temperature {
  font-size: 1.1em;
}

.forecastHP .forecast a {
  color: #fff;
  text-decoration: none;
}

.forecastHP .forecast.tomorrow span, .forecastHP .forecast.today span {
  float: right;
  margin-right: 2px;
  font-size: .8em;
}

.forecastHP .forecast .ico {
  float: right;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") -36px -424px no-repeat;
  width: 22px;
  height: 23px;
  margin-top: 7px;
  padding-right: 5px;
  display: block;
}

.forecastHP .forecast .ico.jasno {
  background-position: 0 -424px;
}

.forecastHP .forecast .ico.polojasno {
  background-position: -36px -424px;
}

.forecastHP .forecast .ico.mlha {
  background-position: -75px -424px;
}

.forecastHP .forecast .ico.mlha-dest {
  background-position: -112px -424px;
}

.forecastHP .forecast .ico.mlha-snih {
  background-position: -148px -424px;
}

.forecastHP .forecast .ico.oblacno {
  background-position: 0 -460px;
}

.forecastHP .forecast .ico.oblacno-bourka, .forecastHP .forecast .ico.polojasno-bourka {
  background-position: -36px -460px;
}

.forecastHP .forecast .ico.oblacno-dest, .forecastHP .forecast .ico.polojasno-dest {
  background-position: -75px -460px;
}

.forecastHP .forecast .ico.oblacno-dest-snih {
  background-position: -110px -460px;
}

.forecastHP .forecast .ico.oblacno-snih {
  background-position: -148px -460px;
}

.forecastHP .forecast .ico.zatazeno {
  background-position: 0 -498px;
}

.forecastHP .forecast .ico.zatazeno-bourka {
  background-position: -35px -498px;
}

.forecastHP .forecast .ico.zatazeno-dest {
  background-position: -73px -498px;
}

.forecastHP .forecast .ico.zatazeno-dest-snih {
  background-position: -111px -498px;
}

.forecastHP .forecast .ico.zatazeno-snih {
  background-position: -147px -498px;
}

#article a {
  text-decoration: underline;
  transition: all .3s;
}

#article a:hover {
  text-decoration: none;
}

#main-promobox .article img {
  width: 100%;
  max-width: 100%;
  top: 0;
}

#main-promobox .article a {
  display: block;
}

#main-promobox .article .wrapper {
  position: relative;
  top: 0;
}

#main-promobox .article .promoboxPosition-0 {
  text-transform: uppercase;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.8em;
  line-height: 1.8em;
  display: block;
}

#main-promobox .article .promoboxPosition-0 h2 {
  text-align: center;
  margin: 0;
}

#main-promobox .article .promoboxPosition-0 h2 a {
  color: #16212d !important;
}

#main-promobox .head {
  color: red;
  float: left;
  text-transform: uppercase;
  text-align: center;
  background: #fff;
  width: 96.875%;
  margin: 30px 1.5625% 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 3.2em;
  line-height: 1.5em;
}

#main-promobox .article.first img {
  width: 100%;
  max-width: 100%;
}

.shadowUp {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") 0 -175px no-repeat;
  width: 300px;
  height: 5px;
  margin: 5px auto;
  display: block;
}

.shadowDown {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") 0 -200px no-repeat;
  width: 300px;
  height: 5px;
  margin: 5px auto;
  display: block;
}

.shadowDown.noMargin {
  margin: 1px auto 0;
}

.categoryMenu {
  margin: 0;
  padding: 0;
  box-shadow: 0 10px 30px -20px rgba(0, 0, 0, .9);
}

ul.categoryMenu li.actualCategory .arrow {
  float: right;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") -25px -225px;
  width: 20px;
  height: 16px;
  display: block;
  position: relative;
  top: 15px;
  right: 10px;
}

ul.categoryMenu:hover li.actualCategory .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") 0 -250px;
}

.categoryMenu li.actualCategory {
  text-transform: uppercase;
  color: #fff;
  background: #848f9b;
  padding: 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 3em;
  line-height: 1.2em;
  list-style: none;
}

.categoryMenu li.actualCategory h1 {
  text-transform: uppercase;
  color: #fff;
  margin: 0;
  padding: 0;
  font-family: tablet_gothic_condensed, arial, sans-serif;
  font-size: 1em;
  list-style: none;
  display: inline;
}

.categoryMenu:hover li.actualCategory, .categoryMenu:hover li.actualCategory h1, .categoryMenu:focus li.actualCategory, .categoryMenu:focus li.actualCategory h1 {
  color: #848f9b;
  background: #fff;
}

ul.categoryMenu ul.subMenu {
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  position: relative;
}

ul.categoryMenu:hover ul.subMenu {
  box-shadow: 0 10px 30px -20px rgba(0, 0, 0, .9);
}

ul.categoryMenu.isOpen ul {
  z-index: 2;
  width: 100%;
  display: block;
  position: absolute;
  top: 4.9em;
  left: 0;
}

ul.categoryMenu ul.subMenu li a, ul.categoryMenu ul.subMenu li div {
  background: #fff;
  border-top: 1px solid #cad5df;
  width: 93.75%;
  padding: 10px 3.125%;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 3em;
  line-height: 1.2em;
  display: block;
}

ul.categoryMenu ul.subMenu li div {
  cursor: pointer;
}

ul.categoryMenu ul.subMenu li a:hover, ul.categoryMenu ul.subMenu li div:hover {
  background: #dee6ee;
}

ul.categoryMenu ul.subMenu li div:hover {
  text-decoration: underline;
}

ul.categoryMenu ul.subMenu li.special-obi {
  color: red;
  background: #fff url("https://img2.cncenter.cz/images/blesk/partner-sekce-obi.png?v=200") -2% / 33% no-repeat;
  border-top: 1px solid #cad5df;
  width: 93.75%;
  height: 3em;
  padding: 10px 3.125%;
  display: block;
}

ul.categoryMenu ul.subMenu li.special-obi a {
  text-align: right;
  text-transform: uppercase;
  background: none;
  border-top: none;
  padding: 3.05% 3.125% 3%;
  font-size: 1.2em;
  line-height: 1.2em;
}

.sectionHeader .subSections li.noBorder {
  border-left: none;
}

.overlay {
  opacity: .5;
  z-index: 1200;
  visibility: hidden;
  background: none;
  min-height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.content {
  min-width: 320px;
  max-width: 100%;
}

.unimenu-h1-h2 {
  background: #848f9b;
  font-size: 25px;
  line-height: 28px;
  position: relative;
  box-shadow: 0 10px 30px -20px rgba(0, 0, 0, .9);
}

.unimenu-h1-h2 h1, .unimenu-h1-h2 h2 {
  color: #fff;
  text-transform: uppercase;
  margin: 0;
  padding: 15px 20px 15px 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 25px;
  line-height: 28px;
  position: relative;
}

.unimenu__title-link {
  color: #fff;
}

.unimenu-h1-h2 .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") -25px -275px;
  border: none;
  width: 10px;
  height: 8px;
  transition: all .6s;
  display: block;
  position: absolute;
  top: 25px;
  right: 10px;
}

.unimenu-h1-h2 .arrow.opened {
  transform: rotate(180deg);
}

.unimenu-h1-h2 .title-nav {
  display: none;
  position: relative;
}

.unimenu-h1-h2 .title-nav h2 {
  text-transform: none;
  margin: 0;
  padding: 0;
  font-size: 25px;
}

.unimenu-h1-h2 .title-nav a {
  color: #6e78dc;
  background: #fff;
  border-top: 1px solid #cad5df;
  width: auto;
  padding: 15px 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  line-height: 28px;
  display: block;
}

.testy .unimenu-h1-h2 .title-nav a, .celebrity .unimenu-h1-h2 .title-nav a {
  color: red;
}

.article-container {
  background: #fff;
  padding: 0 10px;
  position: relative;
}

.article-container.noImage {
  min-height: 8em;
}

.article-container.littleArticle {
  border-top: 2px solid red;
}

.article-container.bigArticle {
  z-index: 100;
  float: left;
}

.article-container .wrapper {
  height: 200px;
  overflow: hidden;
}

.article-container.middleArticle .wrapper {
  height: 10em;
  margin-top: 0;
  overflow: visible;
}

.article-container.middleArticle.noImage .wrapper {
  height: auto;
  margin-left: 0;
  overflow: visible;
}

.article-container.smallArticle .wrapper {
  padding-top: 1em;
  overflow: hidden;
}

.article-container.smallArticle.frantiska .wrapper {
  padding-top: 184px;
  overflow: visible;
}

.littleArticles {
  margin: 10px 0 0;
  padding-bottom: 0;
}

.littleArticles .delimiter {
  clear: both;
  border-bottom: 1px solid #cad5df;
  margin: 10px 10px 0;
}

.article-container.littleArticle .wrapper {
  width: 125px;
  height: 108px;
  margin-top: 10px;
  padding-top: 72px;
  overflow: hidden;
}

.article-container.bigArticle .articlePhoto img {
  background: #fff;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin-top: 10px;
  padding-left: 0;
}

.article-container.bigArticle .articlePhoto .iSport {
  width: 93.75%;
  display: block;
  position: absolute;
  top: 26px;
  left: 10px;
  overflow: hidden;
}

.article-container.bigArticle .articlePhoto .iSport img {
  width: 93.75%;
  max-width: 100%;
  height: auto;
  position: relative;
  top: -30px;
  left: 0;
}

#main-promobox .article-container.middleArticle .imageWrapper {
  left: 0;
}

#main-promobox .article-container.middleArticle .imageWrapper .article-marking {
  color: #fff;
  text-align: right;
  background-color: rgba(152, 163, 175, .8);
  height: 20px;
  padding: 0 10px 0 0;
  font-size: 12px;
  font-weight: bold;
  line-height: 22px;
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
}

.article-container.middleArticle .imageWrapper {
  float: left;
  width: 43.75%;
  max-width: 100%;
  margin-top: 10px;
  padding-bottom: 5px;
  position: relative;
}

.article-container.middleArticle img {
  width: 100%;
  max-width: 100%;
  height: auto;
  position: relative;
}

.article-container.middleArticle .articlePhoto.iSport {
  height: 9em;
  display: block;
  overflow: hidden;
}

.article-container.middleArticle .articlePhoto.iSport img {
  width: 100%;
  max-width: 100%;
  height: auto;
  min-height: 100%;
  position: relative;
}

.article-container.smallArticle .articlePhoto {
  width: 93.75%;
  position: absolute;
  top: 3em;
  left: 10px;
  overflow: hidden;
}

.article-container.smallArticle.frantiska .articlePhoto {
  z-index: 2;
  height: auto;
  top: -57px;
  left: -53px;
  overflow: visible;
}

.article-container.smallArticle .articlePhoto.iSport img {
  max-width: 100%;
  height: auto;
  position: relative;
  top: -20px;
}

.article-container.littleArticle .articlePhoto {
  width: 18.75%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.article-container.bigArticle h2.title, .article-container.bigArticle h3.title {
  color: #fff;
  background: #fff;
  height: 3.4em;
  margin: 0;
  padding-top: 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 2em;
  line-height: 1.2em;
  overflow: hidden;
}

.article-container.bigArticle.bigOne h2.title, .article-container.bigArticle.bigOne h3.title {
  background: red;
  height: 147px;
  font-size: 3.6em;
  line-height: 1.2em;
}

.article-container.bigArticle.bigOne h2.title a, .article-container.bigArticle.bigOne h3.title a {
  color: #fff;
}

.article-container.bigArticle h2.title a, .article-container.bigArticle h3.title a {
  color: #16212d;
  transition: all .1s ease-out;
}

.article-container.bigArticle h3.title a:hover, .article-container.bigArticle h2.title a:hover {
  text-decoration: underline;
}

.article-container .category-title {
  text-transform: uppercase;
  color: red;
  font-family: tablet_gothic_compressed, sans-serif;
  font-size: 1.8em;
  line-height: 2em;
}

.article-container.littleArticle .category-title {
  height: 26px;
  font-size: 1em;
  line-height: 1.2em;
  overflow: hidden;
}

.article-container.middleArticle h2.title, .article-container.middleArticle h3.title {
  color: #16212d;
  float: left;
  letter-spacing: 0;
  width: 50%;
  margin: 8px 0 5px 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 2em;
  line-height: 115%;
  display: block;
  position: relative;
}

.wikiFixTitleMob {
  word-wrap: break-all;
  margin-left: -5px;
  font-size: 18px;
  display: block;
}

.article-container.middleArticle h2.title:hover, .article-container.middleArticle h3.title:hover {
  color: red;
}

.article-container.middleArticle.noImage h2.title, .article-container.middleArticle.noImage h3.title {
  margin: .5em 0;
  top: 0;
}

.article-container.middleArticle h2.title a, .article-container.middleArticle h3.title a {
  color: #16212d;
  transition: all .1s ease-out;
}

.article-container.middleArticle h3.title a:hover, .article-container.middleArticle h3.title a.hoverColor:hover {
  text-decoration: underline;
  color: red !important;
}

.article-container.middleArticle p.perex {
  float: left;
  color: #16212d;
  width: 100%;
  margin: 13px 0 10px;
  font-family: arial, sans-serif;
  bottom: -5px;
  font-size: 1.2em !important;
  line-height: 1.5em !important;
}

.article-container.bigArticle .articleContext {
  color: #c6cbd1;
  font-size: 1.4em;
  line-height: 1.2em;
  position: absolute;
  top: 8px;
  bottom: auto;
  left: auto;
  right: 5px;
}

.article-container.middleArticle .articleContext {
  font-size: 1.2em;
  font-weight: normal;
  top: auto;
  bottom: 1.125%;
  right: auto;
}

.article-container .articleContext {
  color: #c6cbd1;
  padding-top: 2px;
  font-size: 1.2em;
  line-height: 1.2em;
  position: relative;
  left: 0;
}

.article-container .articleContext a {
  color: #98a3af;
}

.article-container .articleContext a:hover {
  text-decoration: none;
}

.article-container.inverted .articleContext a, .alternative .article-container .articleContext * {
  color: #fff;
}

.article-container .articleContext .galleryIcon {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") -25px -75px no-repeat;
  width: 11px;
  height: 9px;
  display: inline-block;
  position: relative;
  top: 0;
}

.alternative .article-container .articleContext .galleryIcon {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0 -850px no-repeat;
}

.article-container .articleContext .forumIcon {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -800px no-repeat;
  width: 13px;
  height: 11px;
  display: inline-block;
}

.alternative .article-container .articleContext .forumIcon {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -850px no-repeat;
}

.article-container .articleContext .sharesIcon {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -50px -800px no-repeat;
  width: 12px;
  height: 10px;
  display: inline-block;
}

.alternative .article-container .articleContext .sharesIcon {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -50px -850px no-repeat;
}

.article-container .articleContext .forumCount, .article-container .articleContext .sharesCount, .article-container .articleContext .galleryCount, .article-container .articleContext .adLabel {
  color: #848f9b;
  margin-right: 5px;
}

.article-container.smallArticle h3 {
  height: 112px;
  margin-top: 17px;
}

.article-container.smallArticle h3 a {
  color: #16212d;
  letter-spacing: 0;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 2.2em;
  line-height: 1.2em;
  transition: all .2s ease-out;
}

.article-container.smallArticle p.perex {
  color: #16212d;
  margin-top: 22px;
  font-family: arial, sans-serif;
  font-size: 1.2em;
  line-height: 1.2em;
}

.article-container.littleArticle h2.title, .article-container.littleArticle h3.title {
  color: #16212d;
  margin: 0;
  font-size: 1.3em;
  line-height: 1.2em;
}

.article-container.littleArticle h2.title a, .article-container.littleArticle h3.title a {
  color: #16212d;
  transition: all .1s ease-out;
}

.article-container.bigOneArticle {
  border-top: 0;
  height: 440px;
}

.article-container.bigOneArticle .title {
  float: left;
  position: relative;
}

.article-container.bigOneArticle .title .arrow {
  width: 14px;
  height: 24px;
  display: block;
  position: absolute;
  top: 20%;
  right: -14px;
  overflow: hidden;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -150px no-repeat !important;
}

.article-container.bigOneArticle h3 {
  float: left;
  color: #fff;
  background: red;
  width: 46.875%;
  height: 328px;
  padding: 0;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 4em;
  line-height: 1.2em;
  display: block;
}

.article-container.bigOneArticle h3 a {
  color: #fff;
  width: 43.75%;
  height: 308px;
  padding: 10px;
  display: block;
  overflow: hidden;
}

.article-container.bigOneArticle h3 a:hover {
  text-decoration: underline;
}

.article-container.bigOneArticle img {
  width: 96.875%;
  max-width: 100%;
  height: 328px;
}

.article-container.bigOneArticle .perex {
  float: left;
  color: #16212d;
  width: 95.3125%;
  padding: 19px 0;
}

.article-container.bigOneArticle .perex a {
  color: #16212d;
  font-size: 1.2em;
  font-weight: bold;
  line-height: 1.2em;
  display: block;
}

.article-container.bigOneArticle .articleContext {
  bottom: 40px;
  left: 640px;
}

.testClass .article-container .iconTest {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16");
  width: 69px;
  height: 64px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.testClass .article-container .iconTest.icon-test_small {
  background-position: -512px -1068px;
}

.infobox {
  box-sizing: border-box;
  background: #fff;
  border: none;
  padding: 5px 2.66% 2.66%;
  display: inline-block;
}

.infobox__title {
  color: #16212d;
  font-family: tablet_gothic_condensed, sans-serif;
  font-size: 2.1em;
  line-height: 60px;
  display: block;
}

.infobox__body {
  font-size: 14px;
}

.infobox__body a {
  text-decoration: underline;
  transition: all .3s;
}

.infobox__body a:hover {
  text-decoration: none;
}

.slevisteContainer .item {
  border-bottom: 1px solid #e4e4e4;
  padding: 20px 0;
  display: block;
  position: relative;
}

.slevisteContainer .item.first {
  padding: 0 0 20px;
}

.article-container.slevisteContainer .wrapper {
  height: auto;
}

.slevisteContainer h2.title, .slevisteContainer h3.title {
  float: right;
  width: 291px;
  height: 75px;
  margin: 0 23px 0 0;
  font-size: 14px;
  line-height: 16px;
  display: block;
  position: relative;
  overflow: hidden;
}

.article-container.slevisteContainer:hover a.hoverColor {
  color: #626161 !important;
}

.article-container.slevisteContainer h2.title a, .article-container.slevisteContainer h3.title a {
  color: #626161;
  text-decoration: none;
}

.article-container.slevisteContainer h2.title a:hover, .article-container.slevisteContainer h3.title a:hover {
  color: #626161;
  text-decoration: underline;
}

.slevisteContainer .articlePhoto {
  float: left;
  border: 1px solid #e4e4e4;
  width: 264px;
  height: 150px;
  display: block;
  position: relative;
  overflow: hidden;
}

.slevisteContainer .cenaPred {
  z-index: 2;
  color: #bbb;
  text-align: right;
  width: 160px;
  font-size: 18px;
  font-weight: bold;
  text-decoration: line-through;
  display: block;
  position: absolute;
  bottom: 50px;
  right: 25px;
}

.slevisteContainer .cenaPred span {
  padding: 0 0 0 5px;
  font-size: 12px;
}

.slevisteContainer .cenaPo {
  z-index: 2;
  color: #626161;
  text-align: right;
  width: 160px;
  font-size: 30px;
  font-weight: bold;
  display: block;
  position: absolute;
  bottom: 10px;
  right: 25px;
}

.slevisteContainer .cenaPo span {
  padding: 0 0 0 5px;
  font-size: 20px;
}

.slevisteContainer .articleArrow {
  z-index: 2;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -30px no-repeat;
  width: 10px;
  height: 20px;
  display: block;
  position: absolute;
  top: 60px;
  right: 0;
}

.slevisteContainer .cenovka {
  z-index: 2;
  background: url("https://img2.cncenter.cz/images/blesk-2013/sleviste-cenovka.png?v=56") no-repeat;
  width: 134px;
  height: 75px;
  display: block;
  position: absolute;
  bottom: 20px;
  left: 266px;
}

.slevisteContainer .cenovka span {
  text-align: center;
  color: #fff;
  width: 90px;
  font-size: 36px;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 8px;
  left: 36px;
}

.slevisteContainer .cenovka span .jednotka {
  padding: 0 0 2px 5px;
  font-size: 24px;
}

.slevisteContainer .cenovka p {
  color: #e59e9e;
  width: 100px;
  height: 25px;
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  display: block;
  position: absolute;
  bottom: 8px;
  left: 50px;
}

#article h1 {
  color: #16212d;
  margin: -5px 10px .5em;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 3.3em;
  line-height: 1.1em;
}

.artContent .perex {
  font-size: 1.2em;
  font-weight: bold;
  line-height: 1.2em;
}

.artContent {
  color: #16212d;
  margin-top: 30px;
  font-size: 2em;
  line-height: 1.2em;
}

.artContent .artTime {
  color: #98a3af;
  font-size: .8em;
}

.artContent .commercial-text {
  color: #16212d;
  background-color: #f1e9c1;
  margin-top: 10px;
  padding: 2px 13px;
  font-size: 16px;
  line-height: normal;
}

.artContent .artAuthors {
  color: #5c6773;
  font-weight: bold;
}

.artContent .dateTimeUpdated {
  color: red;
  font-size: .7em;
  font-weight: bold;
  line-height: 1em;
}

.artContent p {
  margin: 1.5em 0;
}

.artContent p iframe {
  width: 100%;
}

.artContent .artAuthors .title {
  font-weight: normal;
}

.artContent .video-header {
  background-color: #fff;
  padding: 10px;
}

#article #top-img {
  color: #fff;
  background: #16212d;
  margin-bottom: 0;
}

#article #top-img img {
  background: url("https://img2.cncenter.cz/images/blesk-2013/ajax-loader-artimg.gif") 50% no-repeat;
  min-height: 100px;
}

#article #top-img .description {
  padding: 1em .5em;
  font-size: 1.5em;
  line-height: 1.2em;
}

#article #top-img .description .author {
  color: #98a3af;
  padding-left: 10px;
}

#article .articleGallery {
  background: red;
  height: 5em;
  position: relative;
}

#article .articleGallery a {
  color: #fff;
  text-transform: uppercase;
  padding-left: 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 2em;
  line-height: 2.5em;
  display: block;
}

#article .articleGallery a span {
  padding-left: 10px;
}

#article .artImg .imgInArtDescription {
  color: #e9eff4;
  background: #16212d;
  padding: 10px 0 10px 10px;
  font-family: arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
}

#article .artImg .imgAuthor {
  color: #e9eff4;
  background: #16212d;
  margin: 0;
  padding: 0 0 10px 10px;
  font-family: arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 15px;
}

.body--article #article .artImg .imgInArtDescription, .body--article #article .artImg .imgAuthor {
  color: #161616;
  font: var(--barlow-regular);
  background: none;
  padding: 10px 15px;
  font-size: .7em;
  line-height: normal;
  position: relative;
}

#article a.withArrow:after {
  content: "";
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") -25px -225px no-repeat;
  width: 16px;
  height: 20px;
  position: absolute;
  top: 1.1em;
  right: 20px;
}

.linkArticle .wrapper {
  margin-top: 1em;
}

.linkArticle.middleArticle .wrapper h2.title, .linkArticle.middleArticle .wrapper h3.title {
  font-size: 1em;
}

.linkArticle .imageWrapper .articleContext {
  font-size: .6em;
}

article .article-link-color {
  padding: 6px 0 4px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.2em;
  line-height: 1.1em;
  text-decoration: none;
}

#article .article-link-color a {
  color: red;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  text-decoration: none;
}

#article .article-link-color a:hover {
  text-decoration: underline;
}

.article-container.middleArticle.linkArticle img {
  top: 1em;
}

.article-container.middleArticle.linkArticle {
  padding: 0 1.5625%;
  height: auto !important;
}

.article-container.middleArticle.linkArticle .imageWrapper {
  padding-bottom: 0;
}

.article-container.middleArticle.linkArticle .imageWrapper .articlePhoto img {
  top: 0;
}

.article-container.middleArticle.linkArticle .wrapper {
  height: 5.6em;
}

.article-container.middleArticle.linkArticle .wrapper .title {
  height: 3.7em;
}

.article-container.middleArticle.linkArticle .wrapper .articleContext {
  float: left;
  height: 1em;
  font-size: .8em;
}

@font-face {
  font-family: tablet_gothic_condensed;
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont.eot");
  src: url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont.woff") format("woff"), url("//img2.cncenter.cz/fonts/tablet-gothic/tablet_gothic_condensed-bold-webfont.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body.photoGallery {
  background: #16212d;
  margin: 0 auto;
  padding: 0;
  font-family: arial, sans-serif;
}

a img {
  border: none;
  max-width: 100%;
}

#content .content978 {
  width: auto;
}

#top-panel a {
  text-decoration: none;
}

.cb {
  clear: both;
}

#main-gallery {
  width: 100%;
  max-width: 640px;
}

#top-panel {
  background: red;
  height: 80px;
  margin-bottom: 2em;
}

#top-panel .magazineLogo {
  float: left;
  margin-top: -15px;
  margin-left: -55px;
  position: relative;
  top: 50%;
  left: 50%;
}

#top-panel .magazineLogo a img {
  visibility: hidden;
  max-width: 100%;
}

#top-panel .magazineLogo a {
  background: red url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") 5px 2.5px no-repeat;
  width: 110px;
  height: 30px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

#top-panel .galleryTitle {
  float: left;
  color: #16212d;
  margin: 0;
  font-family: tablet_gothic_condensed, arial, sans-serif;
  font-size: 2em;
  font-weight: bold;
  line-height: 1.2em;
  display: inline-block;
}

#controls {
  float: left;
  background: #343f4b;
  width: 100%;
}

#controls .prev {
  float: left;
  text-indent: -9999px;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp") -100px -550px no-repeat;
  width: 20px;
  height: 20px;
}

#photo-controls .thumbsPhoto {
  float: right;
  text-indent: -9999px;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp") 0 -550px no-repeat;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  position: absolute;
  bottom: 10px;
  left: 50%;
}

#photo-controls .thumbsPhoto a {
  width: 100%;
  height: 100%;
  display: block;
}

#controls .next {
  float: right;
  text-indent: -9999px;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp") -150px -550px no-repeat;
  width: 20px;
  height: 20px;
}

#top-panel .galleryTitle:hover {
  text-decoration: underline;
}

#top-panel #close-gallery {
  float: left;
  margin-left: 5px;
}

#top-panel a.close-gallery {
  color: #fff;
  text-transform: uppercase;
  float: right;
  background: red;
  width: auto;
  height: 60px;
  padding: 10px 0 10px 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.5em;
  line-height: 60px;
  display: inline-block;
  position: relative;
  top: 0;
  right: 0;
  overflow: hidden;
}

#top-panel a.close-gallery:hover {
  text-decoration: underline;
}

#photo-container {
  float: left;
  text-align: center;
  background: url("https://img2.cncenter.cz/images/newblesk/galerie-responsive/loader.gif") center no-repeat;
  min-width: 100%;
  margin: 0 auto;
  position: relative;
  height: auto !important;
}

#photo-container img {
  cursor: pointer;
  max-width: 100%;
  width: 100% !important;
  height: auto !important;
}

#photo-container #bottom, #video-box #video-bottom {
  background: #16212d;
  padding: 10px 0 0;
}

#photo-container #bottom #left, #video-box #video-bottom #left {
  text-align: left;
  width: 100%;
  padding: 1em 0;
}

#photo-container #bottom #right, #video-box #video-bottom #right {
  float: left;
  text-align: left;
  width: 100%;
  display: none !important;
}

#photo-container #bottom #left #description, #video-box #video-bottom #left #description, #video-box #video-bottom #left #description a, #photo-container #bottom #left #description a {
  color: #fff;
  font-size: 2em;
  line-height: 1.2em;
}

#photo-container #bottom #left #author, #video-box #video-bottom #left #author {
  color: #e9eff4;
  font-size: 1.6em;
  line-height: 1.2em;
}

.articleVideoTopWrapper {
  max-height: 293px;
  position: relative;
  overflow: hidden;
}

.articleVideoTopCont {
  box-sizing: border-box;
  width: 100%;
  max-width: 438px;
  height: 0;
  max-height: 293px;
  margin-bottom: 20px;
  padding-bottom: 68.25%;
  position: relative;
}

.articleVideoTop {
  max-height: 293px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.photoGallery #photo-container a.thumb {
  float: left;
  width: 48%;
  margin: 1%;
  display: block;
}

#photo-container #vertical-align {
  float: left;
  width: 100%;
  height: 50%;
}

#photo-container #photo-controls {
  background: #343f4b;
  margin: 0 auto;
  padding-bottom: 70px;
  display: inline-block;
  width: 100% !important;
}

#photo-container #photo-controls .nextPhoto {
  float: left;
  cursor: pointer;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") -150px -550px no-repeat content-box border-box;
  width: 20px;
  height: 20px;
  padding: 10px;
  display: block;
  position: absolute;
  right: 10px;
  top: auto !important;
  bottom: 10px !important;
}

#photo-container #photo-controls .prevPhoto {
  float: left;
  cursor: pointer;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") -100px -550px no-repeat content-box border-box;
  width: 20px;
  height: 20px;
  padding: 10px;
  display: block;
  position: absolute;
  left: 10px;
  top: auto !important;
  bottom: 10px !important;
}

#photo-container #photo-controls .prevPhoto.normal:hover img {
  max-width: 100%;
}

#ad-container {
  float: left;
  border: none;
  width: 46.875%;
  margin: -12px 7px 0;
  display: block;
}

#ad-container #ao-skyscraper {
  border: none !important;
  padding: 0 !important;
}

#ad-container #ao-skyscraper .not4bbtext {
  color: #fff;
}

#recent-galleries {
  float: left;
  color: #fff;
  display: none;
}

#recent-galleries .recentGallery {
  float: left;
}

#recent-galleries .recentGallery a, #recent-galleries .recentGallery a img {
  border: 0;
  width: 100%;
  max-width: 100%;
}

#photo-container #photo-controls #position {
  float: right;
  color: #fff;
  opacity: .8;
  background: #141414;
  padding: 0 5px;
  font-size: 2em;
  font-weight: bold;
  position: relative;
  top: -2em;
  right: .5em;
  display: none !important;
}

#photo-container #photo-controls .photoZoom {
  float: right;
  width: 33px;
  height: 33px;
  position: relative;
  top: 43px;
  right: 10px;
  display: none !important;
}

#photo-container #photo-controls .photoZoom a {
  text-indent: -99999px;
  background: url("https://img2.cncenter.cz/images/newblesk/galerie-responsive/zoom-blesk.png") no-repeat;
  width: 33px;
  height: 33px;
  display: block;
  overflow: hidden;
}

#photo-container #photo-controls .photoZoom a:hover {
  background: url("https://img2.cncenter.cz/images/newblesk/galerie-responsive/zoom-blesk.png") 0 -16.5px no-repeat;
}

#photo-container #photo-controls #bottom .shareTweet, #video-box #video-bottom .shareTweet {
  float: left;
  text-align: left;
  width: 90px;
  padding: 2px 0;
}

#photo-container #photo-controls #bottom .shareFB, #video-box #video-bottom .shareFB {
  float: left;
  width: 140px;
  padding: 2px 0;
}

#photo-container #photo-controls #bottom .shareGoogle, #video-box #video-bottom .shareGoogle {
  float: left;
  text-align: right;
  width: 60px;
  padding: 2px 0;
}

#recent-galleries p.head {
  text-transform: uppercase;
  text-align: center;
  color: red;
  margin: 0;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 2em;
  font-weight: bold;
  line-height: 1.2em;
}

#recent-galleries.narrow {
  width: 100%;
  margin-top: 2em;
  margin-right: 0;
  padding: 0;
}

#recent-galleries.narrow .recentGallery {
  width: 33.33%;
  margin: 1em 0;
}

#recent-galleries.narrow p.head {
  font-size: 2em;
}

#recent-galleries.narrow a {
  color: #fff;
  width: 90%;
  margin: 0 5%;
  font-size: 1em;
  font-weight: bold;
  text-decoration: underline;
  display: block;
}

#recent-galleries.narrow a.link {
  padding: 5px 0 10px;
  display: inline-block;
}

#recent-galleries.normal {
  width: 100%;
  margin-top: 2em;
  margin-right: 0;
  padding: 0;
}

#recent-galleries.normal .recentGallery {
  width: 28.333%;
  margin: 1em 2.5%;
  position: relative;
}

#recent-galleries.normal .recentGallery a.link {
  text-align: left;
  color: #fff;
  background: url("https://img2.cncenter.cz/images/newblesk/galerie-responsive/recentGallery-bg.png");
  height: auto;
  padding: 5%;
  font-size: 1.2em;
  font-weight: bold;
  line-height: 1.2em;
  text-decoration: none;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 90% !important;
}

#recent-galleries #left-column .recentGallery, #recent-galleries #right-column .recentGallery {
  width: 100%;
  margin: 0 0 1em;
}

#recent-galleries.normal .recentGallery a.link:hover {
  text-decoration: underline;
}

#recent-galleries.thumbnails {
  width: 87.5%;
  margin: 0 auto;
  padding: 10px 40px;
  display: block;
  margin-left: 0 !important;
}

#recent-galleries.thumbnails p.head {
  text-align: left;
  color: red;
  padding: 0 0 15px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 2.2em;
  line-height: 1.2em;
}

#recent-galleries.thumbnails #left-column {
  float: left;
  width: 48.125%;
}

#recent-galleries.thumbnails #right-column {
  float: right;
  width: 48.125%;
}

#recent-galleries.thumbnails .recentGallery {
  text-align: left;
  margin: 0 4px 8px;
}

ul.selectBox {
  float: left;
  text-indent: 10px;
  cursor: default;
  z-index: 1;
  background: url("https://img2.cncenter.cz/images/newblesk/galerie-responsive/combo-bg.png") no-repeat;
  width: 29.0625%;
  height: 31px;
  margin: 7px 15px 7px 5px;
  padding: 0;
  line-height: 30px;
  display: none;
  position: relative;
  bottom: 0;
  left: 0;
}

ul.selectBox li {
  cursor: default;
  background: #fff url("https://img2.cncenter.cz/images/newblesk/galerie-responsive/combo-bg.png") no-repeat;
  list-style: none;
  display: block;
}

ul.selectBox li:hover {
  background: url("https://img2.cncenter.cz/images/newblesk/galerie-responsive/combo-bg.png") 0 -25px no-repeat;
}

ul.selectBox li a {
  color: #000;
  width: 24.375%;
  text-decoration: none;
  display: block;
  overflow: hidden;
}

#close-video-box {
  float: right;
  text-indent: -9999px;
  margin: 10px;
}

#close-video-box a {
  background: url("https://img2.cncenter.cz/images/newblesk/galerie-responsive/close-videobox.png") no-repeat;
  width: 15px;
  height: 14px;
  display: block;
}

#close-video-box a:hover {
  background: url("https://img2.cncenter.cz/images/newblesk/galerie-responsive/close-videobox.png") 0 -7px no-repeat;
}

main-video-box {
  width: 100%;
  margin: 0 auto;
}

#ad-container-top {
  width: 100%;
}

#video-box {
  float: left;
  width: 93.75%;
  margin: 20px 78px 110px 0;
}

main-video-box #ad-container {
  float: left;
  width: 46.875%;
  height: 600px;
  margin: 20px 0;
}

.blackLayer {
  z-index: 500;
  background: url("https://img2.cncenter.cz/images/opacity.png");
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

#click-ad-container {
  z-index: 1000;
  text-align: center;
  background: url("https://img2.cncenter.cz/images/opacity80.png");
  width: 100%;
  min-height: 270px;
  margin: 0 auto;
  padding: 0;
  display: none;
  position: absolute;
}

#click-ad-container #close-container-cross {
  float: right;
  cursor: pointer;
  background: url("https://img2.cncenter.cz/images/newblesk/galerie-responsive/close-adcontainer.png") no-repeat;
  width: 37px;
  height: 37px;
  display: block;
  position: relative;
  top: -18px;
  right: -37px;
}

#click-ad-container #close-container {
  text-align: center;
  cursor: pointer;
  background: url("https://img2.cncenter.cz/images/newblesk/galerie-responsive/zpet-na-clanek.png?v=2") 0 -44px no-repeat;
  width: 49.2188%;
  height: 22px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: inline-block;
}

#click-ad-container #close-container:hover {
  background: url("https://img2.cncenter.cz/images/newblesk/galerie-responsive/zpet-na-clanek.png?v=2") 0 -55px no-repeat;
}

.topTools {
  background-color: #85898f;
}

.topTools .whiteCont {
  display: none;
}

.topTools .toolOn .whiteCont {
  float: none;
  color: #959595;
  background-color: #858585;
  border: 1px solid #fff;
  height: 25px;
  margin: 0;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.2em;
  position: absolute;
  top: 25px;
  left: 0;
  display: block !important;
}

.embedCont {
  margin: 0 4px;
  position: relative;
}

.embedCont, .videoInArticle .embedCont a {
  float: left;
}

.embedCont a {
  color: #fff;
  float: left;
  height: 25px;
  padding: 0 7px;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.2em;
}

.embedCont a:hover, .videoInArticle .toolOn a.embedA {
  color: #fff;
  background: #a5a5a5;
}

.embedCont input {
  border-collapse: collapse;
  background: none;
  border: 0;
  width: 46.875%;
  margin: 4px 0 0 5px;
  font-weight: normal;
}

#cbox-overlay {
  background: url("https://img2.cncenter.cz/images/opacity80.png") !important;
}

.colorBtn, .lightBtn, .darkBtn {
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  border: none;
  margin: .2em 0;
  padding: 0 5%;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 3em;
  font-weight: bold;
  line-height: 1.5em;
  display: inline-block;
  position: relative;
}

.colorBtn {
  color: #fff;
  background: red;
  width: 90%;
  line-height: 1.5em;
}

.colorBtn:hover {
  background: #eb0000;
}

.lightBtn {
  color: #707b87;
  background: #f2f5f8;
  border: 1px solid #cad5df;
  height: 28px;
  line-height: 28px;
}

.lightBtn:hover {
  background: #e9eff4;
}

.darkBtn {
  color: #fff;
  background: #5c6773;
  width: 90%;
  line-height: 1.5em;
}

.darkBtn:hover {
  background: #48535f;
}

.colorBtn.disableBtn, .lightBtn.disableBtn, .darkBtn.disableBtn {
  color: #e9eff4;
  background: #cad5df;
}

.forumArticle {
  width: 100%;
  margin: 30px 1.5625% 0;
}

.headerComments {
  color: #16212d;
  border-top: 3px solid #404a55;
  margin-bottom: 20px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 2.5em;
  line-height: 1.2em;
}

.forumArticle .commentsBox .commentsBubble p {
  margin: 0;
  font-size: 1.4em;
  line-height: 1.2em;
}

.headerComments a.colorBtn {
  color: #fff;
  margin: 15px 0;
  font-size: 1.2em;
}

.headerComments a {
  color: #16212d;
  text-transform: uppercase;
  font-size: 1.5em;
  line-height: 1.2em;
}

.forumArticle .commentsBox .commentsBubble {
  color: #16212d;
  background: #fff;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 1em;
  line-height: 1.2em;
}

.forumArticle .commentsCount {
  color: #98a3af;
  font-family: tablet_gothic_compressed, sans-serif;
  font-size: .8em;
  line-height: 1.2em;
}

.forumArticle .commentsCount.noComments {
  margin-left: 260px;
}

.forumArticle .commentsBox .commentsBubble p .nick {
  float: left;
  margin-right: 10px;
  font-weight: bold;
}

.forumArticle .commentsBox .commentsBubble.noComments {
  text-align: center;
}

.commentsBox .headerSimple {
  margin: 10px 0;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 3em;
  line-height: 1.2em;
}

.commentsBox .level0 {
  background: #fff;
  margin-top: 10px;
  padding: 0;
}

.commentsBox .level1 {
  background: #fff;
  padding: 10px 0 0 40px;
}

.commentsBox .level2 {
  background: #fff;
  padding: 10px 0 0 80px;
}

.commentsBox .level3 {
  background: #fff;
  padding: 10px 0 0 120px;
}

.commentsBox .level4 {
  background: #fff;
  padding: 10px 0 0 160px;
}

.commentsBox .level5 {
  background: #fff;
  padding: 10px 0 0 200px;
}

.commentsBox .message {
  background: #fff;
  padding: 10px;
}

.commentsBox .metaMessage {
  color: #23aaff;
  float: left;
  margin-top: 10px;
  margin-left: 10px;
}

.commentsBox .metaMessage a {
  color: #23aaff;
  font-size: 1em;
  font-style: italic;
  line-height: 1.2em;
}

.commentsBox .metaMessage a.notify {
  margin-left: 10px;
}

.commentsBox .message .date {
  color: #98a3af;
  float: left;
  margin-top: 10px;
  font-size: 1em;
  line-height: 1.2em;
  display: block;
}

.commentsBox .message p {
  color: #16212d;
  font-size: 1.5em;
  line-height: 1.2em;
}

.commentsBox .message.deleted {
  text-decoration: line-through;
}

.commentsBox .message .author {
  color: #16212d;
  margin-right: 10px;
  font-size: 1.1em;
  font-style: italic;
  font-weight: bold;
  line-height: 1.2em;
}

.forumLoginBox .input input {
  color: #343f4b;
  background: #fff;
  border: 1px solid #ecf0f4;
  width: 100%;
  margin: 0;
  padding: 5px;
  font-size: 1.2em;
  line-height: 1.2em;
}

.button {
  text-transform: uppercase;
  float: right;
  cursor: pointer;
  color: #fff;
  border: none;
  margin: 10px 0;
  padding: 7px 45px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.2em;
  line-height: 1.2em;
  text-decoration: none;
  position: relative;
}

.button:after {
  z-index: -1;
  content: "";
  background: #777;
  width: 50%;
  max-width: 100px;
  position: absolute;
  top: 8px;
  bottom: 20%;
  left: auto;
  right: 2px;
  transform: rotate(7deg);
  box-shadow: 0 2px 10px #777;
}

.button.red {
  color: #fff;
  text-align: center;
  background: red;
  width: 100%;
  margin-top: 1em;
  padding: .5em 0;
  font-size: 2em;
}

.button.red:hover {
  background: #eb0000;
  text-decoration: none;
}

.button .nextArrow:after {
  content: "";
  border: 5px solid rgba(0, 0, 0, 0);
  border-left-color: #fff;
  width: 0;
  height: 0;
  position: absolute;
  top: 10px;
  right: 6px;
}

.forumLoginBox .submitButton {
  background: red;
  width: 100%;
  margin-top: 1em;
  padding: .5em 0;
  font-size: 2em;
}

.loginCont {
  color: #707b87;
  font-size: 1em;
  line-height: 1.2em;
}

.loginCont a {
  color: #707b87;
  float: left;
  padding: 0 15px;
  font-size: 1em;
  line-height: 1.2em;
  display: block;
}

.loginCont .input {
  float: left;
  width: 90%;
  margin: 2% 5%;
}

.loggedBox {
  border-top: 1px solid #cad5df;
  margin: 20px 0 0;
  padding-top: 15px;
}

.loggedBox .formButtonProfile {
  border-left: 1px solid #707b87;
  border-right: 1px solid #707b87;
}

.insertForm textarea {
  resize: none;
  color: #16212d;
  border: 1px solid #cad5df;
  width: 96.5625%;
  height: 98px;
  padding: 10px;
}

.insertForm .formInputs {
  background: #fff;
  border: 1px solid #cad5df;
  border-top: none;
  width: 100%;
  padding: 10px;
}

.insertForm .notify {
  color: #707b87;
  float: left;
  margin: 5px 0;
  font-size: 1em;
  line-height: 1.2em;
}

.loginCont a.formButtonLogout {
  float: right;
}

.loginCont .headerSimple {
  float: left;
  color: #16212d;
  padding: 0 20px 0 10px;
  font-weight: bold;
}

.insertForm .formInputs .remaining {
  float: left;
  color: #98a3af;
  margin: 8px 10px;
  font-size: .8em;
  line-height: 1.2em;
}

.insertForm .formInputs .smilies {
  margin: 5px;
}

.insertForm .formInputs .smilies a:hover {
  text-decoration: none;
}

.loginCont .headerSimple .userIco {
  float: left;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -900px -350px no-repeat;
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

.loginCont .headerSimple .userNick {
  float: left;
}

.insertForm .formCond {
  float: left;
  margin: 5px 10px;
}

.insertForm .formButtonCond {
  color: #23aaff;
  font-size: 1em;
  line-height: 1.2em;
}

#badwords-cont {
  display: none;
}

.diskCont {
  margin: 15px;
}

.diskCont .headerGray {
  border-top: 3px solid;
  margin: 10px 0;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 2.5em;
  line-height: 1.2em;
}

.diskCont .warning--unverified {
  color: #16212d;
  background: #fff;
  border: 1px solid #cad5df;
  width: 96.5625%;
  padding: 30px 10px;
  font-size: 15px;
  line-height: 18px;
}

.loggedBox .forumAdminPanel a {
  color: #23aaff;
  font-size: 1em;
}

.forumAdminPanel {
  border-top: 1px solid #cad5df;
  padding: 10px;
}

.forumAdminPanel .showReportedComments {
  float: left;
  line-height: 30px;
}

.forumAdminPanel .showReportedComments a {
  margin: 0;
  padding: 0;
}

.forumAdminPanel .discussionStatus {
  float: right;
  line-height: 30px;
}

.commentsBox .metaAdmin {
  text-align: right;
  color: #707b87;
  font-size: .9em;
}

.commentsBox .metaAdmin a {
  color: #23aaff;
  font-size: .9em;
  font-style: italic;
}

.commentsBox .author a {
  color: #23aaff;
  font-size: 1em;
}

.forumLoginBox .terms label a {
  float: none;
  padding: 0;
  display: inline;
}

.forumLoginBox .terms, .forumLoginBox .forgot {
  margin: 10px 0 20px;
}

.pagination {
  text-align: center;
  color: #707b87;
  margin: 20px 0;
}

.pagination a {
  color: #707b87;
  margin: 0 7px;
  font-size: 1em;
  font-weight: bold;
  line-height: 20px;
  text-decoration: none;
}

.pagination a.aktpage {
  color: red;
}

.pagination a:hover {
  color: #16212d;
}

.pagination a.nextPage {
  text-indent: -9999px;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -900px -400px no-repeat;
  width: 10px;
  display: inline-block;
}

.pagination a.nextPage:hover {
  background-position: -900px -450px;
}

.pagination a.prevPage {
  text-indent: -9999px;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -850px -400px no-repeat;
  width: 10px;
  display: inline-block;
}

.pagination a.prevPage:hover {
  background-position: -850px -450px;
}

.pagination.wikiSearch {
  text-align: center;
  color: #707b87;
  margin: 20px 10px;
}

.pagination.wikiSearch .pages {
  float: inherit;
}

.pagination.wikiSearch .pages a {
  color: #707b87;
  background-image: inherit;
  margin: 0 7px;
  font-size: 17px;
  font-weight: bold;
  line-height: 24px;
  text-decoration: none;
}

.pagination.wikiSearch .pages a.aktpage {
  color: red;
  background: inherit;
  border-color: inherit;
}

.pagination.wikiSearch .pages a:hover {
  color: #16212d;
  border-color: inherit;
  background: inherit;
}

.pagination.wikiSearch .pages a.nextPage {
  text-indent: -9999px;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -900px -400px no-repeat;
  width: 10px;
  display: inline-block;
}

.pagination.wikiSearch .pages a.nextPage:hover {
  background-position: -900px -450px;
}

.pagination.wikiSearch .pages a.prevPage {
  text-indent: -9999px;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -850px -400px no-repeat;
  width: 10px;
  display: inline-block;
}

.pagination.wikiSearch .pages a.prevPage:hover {
  background-position: -850px -450px;
}

.breakingNew {
  color: #fff;
  background: #16212d;
  height: auto;
  padding-bottom: .3em;
  position: relative;
}

.breakingNew .header {
  text-transform: uppercase;
  background: rgba(255, 0, 0, .9) url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") -242px -765px no-repeat;
  height: 40px;
  margin: 0 0 .5em;
  padding: 0 0 0 2.2em;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 2.3em;
  line-height: 1.3em;
  overflow: hidden;
}

.breakingNew .header .extraNew {
  float: left;
  padding-left: 10px;
}

.breakingNew .header .globe {
  opacity: .5;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") -250px -225px no-repeat;
  width: 37px;
  height: 37px;
  position: absolute;
  top: 2%;
  left: 48%;
}

.breakingNew .header .status {
  float: right;
  color: #16212d;
  padding-right: 10px;
  font-size: .7em;
}

.breakingNew .title {
  text-transform: uppercase;
  text-align: center;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 8em;
  line-height: 1.3em;
}

.breakingNew .title a {
  color: #fff;
}

.breakingNew .title a:hover {
  text-decoration: underline;
}

.breakingNew .image img {
  width: 100%;
  max-width: 100%;
  display: none;
}

.breakingNew.black {
  background-color: #16212d;
}

.breakingNew.black .header {
  background-color: red;
}

.breakingNew.black .header .status {
  color: #16212d;
}

.breakingNew.red {
  background-color: red;
}

.breakingNew.red .header {
  background-color: #16212d;
}

.breakingNew.red .header .status {
  color: red;
}

.breakingNew.yellow {
  background-color: gold;
}

.breakingNew.yellow .header {
  background-color: red;
}

.breakingNew.yellow .header .status {
  color: gold;
}

.breakingNew.yellow .title {
  color: #16212d;
}

.image img {
  width: 100%;
}

ul.tabsNavigation {
  margin: 0;
  padding: 0;
}

.tabsNavigation li {
  float: left;
  width: 46.875%;
  margin: 0;
  display: block;
}

.tabsNavigation li:first-child {
  margin: 0 3.125% 0 0;
}

.tabsNavigation a {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  background-color: #98a3af;
  width: 100%;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 2em;
  line-height: 2em;
  text-decoration: none;
  transition: all .2s ease-out;
  display: block;
  position: relative;
}

.tabsNavigation a:hover, .tabsNavigation a.active {
  background-color: red;
}

.tabsNavigation a.active:after {
  content: "";
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -50px -900px no-repeat;
  width: 20px;
  height: 10px;
  position: absolute;
  top: 2em;
  left: 45%;
}

.homepageTabs .inner_4 {
  margin: 0;
}

.homepageTabs .latestArticles {
  height: 330px;
}

.latestArticles h4.header {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  background: red;
  width: 99px;
  margin-bottom: 20px;
  padding: 5px 10px;
  font-family: tablet_gothic_compressed, sans-serif;
}

.latestArticles .item .time {
  float: left;
  color: #98a3af;
  width: 15.4688%;
  font-family: arial, sans-serif;
  font-size: 1.5em;
  font-weight: normal;
  line-height: 1.2em;
}

.latestArticles .item .position {
  float: left;
  color: #98a3af;
  width: 60px;
  font-family: arial, sans-serif;
  font-size: 2em;
  font-weight: bold;
  line-height: 1.2em;
}

.latestArticles .item .title {
  float: left;
  width: 78.125%;
}

.latestArticles .item .image {
  float: left;
  width: 120px;
  height: 68px;
  overflow: hidden;
}

.latestArticles .item .image img {
  width: 120px;
  max-width: 100%;
}

.latestArticles.promoboxSmall .item .title {
  float: right;
  width: 24.2188%;
  padding-right: 15px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.1em;
}

.latestArticles .item {
  background-color: rgba(0, 0, 0, 0);
  border-top: 0;
  border-bottom: 1px solid #cad5df;
  padding: 10px 0 9px;
  position: relative;
}

.latestArticles .item:first-child {
  padding: 0 0 9px;
}

.latestArticles .item .title a {
  color: #16212d;
  font-size: 1.5em;
  font-weight: bold;
  line-height: 1.2em;
  transition: all .1s ease-out;
}

.latestArticles.promoboxSmall .item .title a {
  font-size: 1.1em;
  font-weight: normal;
  line-height: 1.2em;
}

.latestArticles .showAll {
  text-align: right;
  color: red;
  margin: 5px 0;
}

.latestArticles .showAll a {
  color: red;
  text-decoration: none;
}

.latestArticles .showAll a:hover {
  text-decoration: underline;
}

.latestArticles .categoryArrow {
  float: left;
  width: 10px;
  height: 10px;
  margin: auto;
  position: absolute;
  top: 50%;
  right: 0;
}

.latestArticles .categoryArrow .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -250px -216px no-repeat;
  width: 8px;
  height: 10px;
  display: block;
}

.goToLink {
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: red;
  height: 30px;
  margin: 20px 10px 10px;
  padding: 0 10px;
  font-family: tablet_gothic_compressed, sans-serif;
  font-size: 1.2em;
  line-height: 1.2em;
  display: block;
}

.whiteArrow {
  float: right;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0 -900px no-repeat;
  width: 8px;
  height: 10px;
  display: inline-block;
  position: relative;
  top: 10px;
  right: 0;
}

.enquiryHP {
  color: #99a4aa;
  background: #fff;
  border-top: 4px solid #16212d;
  padding: 10px;
  font-size: .4em;
  position: relative;
}

.enquiryHP .title {
  text-transform: uppercase;
  font-family: tablet_gothic_compressed, sans-serif;
  font-size: 1.1em;
  line-height: 1.2em;
}

.enquiryHP .question {
  color: #16212d;
  text-align: center;
  letter-spacing: 0;
  margin-bottom: 20px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 2.5em;
  line-height: 1.2em;
  position: relative;
  top: 0;
}

.enquiryHP .firstChoice {
  float: left;
  background: red;
  width: 50%;
  min-width: 31.25%;
  max-width: 68.6%;
}

.enquiryHP .firstChoice .image, .enquiryHP .firstChoice .answer, .enquiryHP .firstChoice .percents {
  float: left;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 2.5em;
  line-height: 1.2em;
}

.enquiryHP .firstChoice .answer {
  color: #fff;
  padding: 0 10px;
}

.enquiryHP .firstChoice .percents {
  color: #540101;
}

.enquiryHP .secondChoice {
  float: right;
  background: #5c6773;
  min-width: 31.25%;
  max-width: 68.6%;
}

.enquiryHP .secondChoice .image, .enquiryHP .secondChoice .answer, .enquiryHP .secondChoice .percents {
  float: right;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 2.5em;
  line-height: 1.2em;
}

.enquiryHP .secondChoice .answer {
  color: #fff;
  margin: 0 10px;
}

.enquiryHP .secondChoice .percents {
  color: #16212d;
  padding: 0;
}

.enquiryHP .votes {
  margin-top: 10px;
  font-size: 1.3em;
}

.enquiryHP .votes .first {
  float: left;
}

.enquiryHP .votes .second {
  float: right;
}

.container .inner_7 .article-body .inner_12 {
  width: 96.875%;
  margin: 0 0 0 -90px;
}

.container .inner_7 .article-body .enquiryHP .firstChoice, .container .inner_7 .article-body .enquiryHP .secondChoice {
  min-width: 39.8438%;
  max-width: 53.9063%;
}

.recentVideos {
  background: #16212d;
  margin: 3em 10px;
  overflow: visible;
}

.recentArticles.recentVideos {
  float: left;
  margin: 3em 1.5625%;
}

.recentArticles.recentVideos .item {
  height: 27em;
}

.recentArticles.recentVideos .header {
  color: #b6c1cc;
  float: none;
  background: none;
  height: auto;
  margin: 0;
  padding: 0 0 0 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 3em;
  line-height: 1.2em;
}

.recentArticles.recentVideos .left {
  float: left;
  border-bottom: 1px solid #343f4b;
  width: 96.875%;
  height: 6.5em;
  margin-bottom: 20px;
  position: relative;
}

.recentArticles.recentVideos .left .starTeamIcon {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=8") -150px 0 no-repeat;
  width: 65px;
  height: 70px;
  position: absolute;
  bottom: 0;
  right: 10px;
}

.forumBoxReturn {
  margin: 20px 15px;
}

.recentArticles.recentVideos .header a {
  color: #b6c1cc;
}

.recentArticles.recentVideos .partyWithMarketa {
  display: block;
}

.recentArticles.recentVideos .partyWithMarketa a {
  color: #b469f5;
  padding-left: 10px;
  font-family: tablet_gothic_compressed, sans-serif;
  font-size: 2.2em;
  line-height: 1.2em;
}

.recentArticles.recentVideos .item .image {
  float: left;
  width: 100%;
  max-width: 100%;
  position: relative;
}

.recentArticles.recentVideos .item .image img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.recentArticles.recentVideos .item .title {
  float: left;
  width: 100%;
  margin: 0;
  padding: 1em 0 2em;
}

.recentArticles .article-container {
  background: none;
  border-top: none;
}

.recentArticles.topArticles .article-container.middleArticle {
  background: #fff;
  border-top: none;
  margin-top: 5px;
}

.recentArticles.recentVideos .item .title a {
  color: #fff;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.8em;
  line-height: 1.2em;
}

.recentArticles.recentVideos .item .views {
  color: #fff;
  font-size: .9em;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  right: 10px;
}

.recentArticles.recentVideos .item .iconPlay {
  z-index: 1;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  position: absolute;
  top: 50%;
  left: 50%;
}

a span.normalArrow {
  font-family: arial, sans-serif;
  font-size: .75em;
}

.sectionHeader {
  border-top: 4px solid red;
  margin: 15px 1.5625% 0;
  padding: 0;
  position: relative;
  overflow: visible;
}

.sectionHeader .categoryLogo {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") no-repeat;
  width: 170px;
  height: 75px;
  position: absolute;
  top: -5px;
  left: 0;
}

.proMuze .sectionHeader .categoryLogo {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -962px -958px no-repeat;
}

.hobby .sectionHeader .categoryLogo {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -962px -1050px no-repeat;
}

.sectionHeader h1, .sectionHeader h2 {
  color: #16212d;
  float: left;
  text-transform: uppercase;
  letter-spacing: 0;
  margin: 5px 0 0;
  font-family: tablet_gothic_compressed, sans-serif;
  font-size: 3em;
  line-height: 1.2em;
}

.sectionHeader.bg h1, .sectionHeader.bg h2 {
  margin: 0 10px;
}

.sectionHeader h1 a, .sectionHeader h2 a {
  color: #16212d;
  transition: all .1s ease-out;
}

.sectionHeader .subSections {
  float: right;
  line-height: 50px;
}

.sectionHeader .subSections li {
  color: red;
  border-left: 1px solid #cad5df;
  padding: 0;
  display: inline-block;
}

.sectionHeader .subSections li.last {
  padding-right: 0;
}

.sectionHeader .subSections a {
  color: red;
  text-transform: uppercase;
  padding: 0 20px;
  font-family: tablet_gothic_compressed, sans-serif;
  font-size: 1.2em;
  line-height: 1.2em;
  display: block;
}

.sectionHeader .shadowDown {
  top: 10px;
}

.sectionHeader .dropdown {
  z-index: 100;
  width: 39.0625%;
  padding-top: 20px;
  display: none;
  position: absolute;
  top: 55px;
  right: 0;
}

.sectionHeader .dropdown .spaceWrapper {
  height: 19px;
  background: #e9eff4 !important;
}

.sectionHeader .dropdown.withSpace {
  padding-top: 0;
}

.sectionHeader .dropdown li {
  float: none;
  border-left: 0;
  display: block;
}

.sectionHeader .dropdown li a {
  border-top: 1px solid #fff;
}

.sectionHeader .subSections li:hover .dropdown {
  display: block;
}

.sectionHeader li a.dropdownMenu {
  margin-right: 10px;
}

.sectionHeader li a.dropdownMenu:after {
  content: "";
  background-origin: content-box;
  background-clip: content-box;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 34px;
  right: 10px;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -250px -200px no-repeat !important;
}

.galleryHP {
  color: #fff;
  background: #16212d;
  margin: 20px 1.5625%;
  padding: 0;
}

.galleryHP .title {
  color: #b6c1cc;
  text-transform: uppercase;
  padding: 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.8em;
  line-height: 1.2em;
  display: inline-block;
}

.galleryHP .galleryLink {
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.8em;
  line-height: 35px;
}

.galleryHP .photo {
  padding: 0 10px 20px;
}

.divkaDne .article-container {
  color: #c0ced5;
  background: #16212d;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.divkaDne .article-container .topLayer {
  color: #c0ced5;
  z-index: 5;
  width: 50%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.divkaDne .article-container .topLayer:hover {
  text-decoration: none;
}

.divkaDne .article-container .mainImage {
  z-index: 2;
  width: 50%;
  position: relative;
}

.divkaDne .article-container h3 {
  z-index: 5;
  text-transform: uppercase;
  width: 45%;
  margin: 0 2.5%;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 3em;
  line-height: 1.2em;
  display: block;
  position: absolute;
  top: 1em;
  right: 0;
}

.divkaDne .article-container h3 a {
  color: #fff;
  border-bottom: 10px solid #fff;
  transition: all .1s ease-out;
  display: block;
}

.divkaDne .article-container h3 a .superTitle {
  font-size: 16px;
  line-height: 20px;
}

.divkaDne .article-container h3 a:hover {
  border-bottom: 10px solid #8b7c5e;
}

.divkaDne .article-container h3 a:hover, .divkaDne .article-container h3 a:hover * {
  text-decoration: none;
}

.divkaDne .article-container .joke {
  z-index: 5;
  width: 45%;
  margin: 1.5em 2.5%;
  display: block;
  position: absolute;
  top: 9em;
  right: 0;
}

.divkaDne .article-container .joke .today span {
  text-indent: -9999px;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0 -216px no-repeat;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  bottom: 30px;
  right: -10px;
}

.divkaDne .article-container .joke .today {
  color: #707b87;
  font-size: 1.3em;
  font-weight: bold;
  line-height: 1.2em;
}

.divkaDne .article-container .joke a {
  color: #e9eff4;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 2.4em;
  line-height: 1.2em;
}

.divkaDne .article-container .joke.yesterday {
  width: 60px;
  bottom: 20px;
  left: 220px;
}

.divkaDne .article-container .joke.yesterday img {
  border: 5px solid #6f634b;
  width: 50px;
  height: 90px;
}

.divkaDneDetail {
  color: #c0ced5;
  background: #16212d;
}

.divkaDneDetail h1 {
  margin-bottom: 0;
  padding: 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 3em;
  line-height: 1.2em;
}

.divkaDneDetail .jokeContainer {
  color: #fff;
  background-color: #8b7c5e;
  padding: 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 2em;
  line-height: 1.2em;
}

.divkaDneDetail .jokeContainer .header {
  color: #fff;
  text-transform: uppercase;
  margin: 0 0 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.5em;
  line-height: 1.2em;
}

.divkaDneDetail .detail {
  height: 38em;
  min-height: 300px;
  margin: 0 10px 0 0;
  position: relative;
}

.divkaDneDetail .detail .image {
  float: left;
  max-width: 45%;
  margin: 2.5%;
}

.divkaDneDetail .detail .smsCode {
  color: #cad5df;
  text-align: center;
  width: 45%;
  font-size: 1em;
  line-height: 1.2em;
  position: absolute;
  top: 21em;
  right: 10px;
}

.divkaDneDetail .detail .smsCode .or {
  font-weight: bold;
  display: block;
}

.divkaDneDetail .detail .smsCode .title {
  text-align: center;
  color: #fff;
  margin: 0 0 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 2em;
  line-height: 1.2em;
}

.divkaDneDetail .voting .top {
  float: left;
  border-top: 1px solid #848f9b;
  border-bottom: 1px solid #848f9b;
  width: 50%;
  margin: .6em 0;
  padding: 10px 0;
}

.divkaDneDetail .voting .top .header {
  text-align: center;
  color: #848f9b;
  font-size: 12px;
}

.divkaDneDetail .voting .votes {
  text-align: center;
  letter-spacing: -1px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 6em;
  line-height: 1.2em;
}

.divkaDneDetail .voting .voteNumbers {
  text-align: center;
  margin: 5px 0 0;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
}

.divkaDneDetail .voting .voteNumbers a {
  color: #848f9b;
  margin: 0 10px;
  text-decoration: none;
  display: inline-block;
}

.fashionPolice .superTitle {
  color: #00c8be;
}

.fashionPolice.artContent .leadsection {
  border-bottom: 4px solid #00c8be;
  margin-bottom: 1em;
  padding-bottom: 5em;
  padding-right: 0;
  position: relative;
}

.fashionPolice .leadsection .fashionPoliceIcon {
  float: right;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") -50px -225px no-repeat;
  width: 186px;
  height: 121px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
}

.police-carousel {
  color: #cad5df;
  background: #16212d;
  width: 95%;
  margin: 10px 0;
  padding: 1em 2.5%;
  position: relative;
  left: 0;
}

#police-carousel {
  margin: 10px 0 0;
  padding: 0;
}

.police-carousel .fpMainLeft {
  float: left;
  width: 100%;
  height: auto;
  position: relative;
}

.fpMainLeft__img-nav {
  z-index: 10;
  cursor: pointer;
  background: rgba(30, 30, 30, .6);
  width: 60px;
  height: 100%;
  display: inline-block;
  position: absolute;
  top: 0;
}

.fpMainLeft__img-nav--left {
  left: 0;
}

.fpMainLeft__img-nav--right {
  right: 0;
}

.fpMainLeft__nav-icon {
  width: 11px;
  height: 20px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
}

.fpMainLeft__img-nav--left .fpMainLeft__nav-icon {
  background: url("https://img2.cncenter.cz/images/blesk/arrow-light-left.png?v=1") no-repeat;
  left: 4%;
  right: 4%;
}

.fpMainLeft__img-nav--right .fpMainLeft__nav-icon {
  background: url("https://img2.cncenter.cz/images/blesk/arrow-light-right.png?v=1") no-repeat;
  left: 25px;
}

.police-carousel .fpMainRight {
  float: right;
  width: 100%;
}

.police-carousel .fpMainRightName {
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.6em;
  line-height: 1.2em;
}

.police-carousel .fpMainRightVerdict {
  float: left;
  color: #fff;
  font-size: 1.1em;
  font-weight: normal;
  line-height: 1.2em;
}

.police-carousel .fpMainRightRatingSign {
  color: #00c8be;
  text-align: center;
  float: left;
  width: .7em;
  margin-left: 0;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 6em;
  line-height: 1em;
  display: inline-block;
  position: relative;
  left: 33%;
}

.police-carousel .fpEnqQuestion {
  color: #848f9b;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: .9em;
  font-weight: normal;
  line-height: 1em;
}

.police-carousel .fpEnqVotingWrapper {
  float: left;
  width: 100%;
  margin: 0;
  padding: 10px 0;
}

.police-carousel .fpEnqVotingWrapper .fpEnqMiddle {
  position: relative;
}

.police-carousel .fpCarousel {
  z-index: 1;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 0;
  margin-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
}

.police-carousel .fpCarousel ul {
  position: relative;
}

.police-carousel .fpCarousel li {
  float: left;
  cursor: pointer;
  margin: 0 0 5px;
}

.police-carousel .fpCarousel li img {
  width: 50px;
  max-width: 100%;
  height: 90px;
  margin: 0 10px;
}

.police-carousel .votesFpFirstChoice {
  float: left;
  text-align: center;
  width: 2.7em;
  margin-top: 0;
  padding-top: 0;
  font-size: 1em;
  position: absolute;
  top: .4em;
}

.police-carousel .votesFpSecondChoice {
  float: right;
  text-align: center;
  width: 2.7em;
  padding-top: 0;
  font-size: 1em;
  position: absolute;
  top: .4em;
  right: 0;
}

.police-carousel .fpEnqFirstChoiceWrapper a {
  float: left;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") -250px -50px no-repeat;
  width: 29px;
  height: 30px;
  display: inline-block;
  position: relative;
  top: 2em;
  left: .7em;
}

.police-carousel .fpEnqSecondChoiceWrapper a {
  float: right;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") -250px 0 no-repeat;
  width: 29px;
  height: 30px;
  display: inline-block;
  position: relative;
  top: 2em;
  right: .7em;
}

.police-carousel .fpEnqWrapper {
  clear: both;
  position: relative;
  top: -40px;
}

.police-carousel .controls {
  background: none;
  width: 100%;
  height: 90px;
  padding: 0%;
  line-height: 1.2em;
  display: none;
  position: absolute;
  top: 10px;
  left: 0;
}

.fashionPoliceList {
  margin: 0;
  padding: 0;
}

.police-carousel a.jcarousel-prev {
  color: #fff;
  float: left;
  text-transform: uppercase;
  background-position-x: -450px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.1em;
  line-height: 2em;
  top: 0;
}

.police-carousel a.jcarousel-next {
  color: #fff;
  float: right;
  text-transform: uppercase;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -450px -216px no-repeat content-box content-box;
  padding-right: 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.1em;
  line-height: 2em;
  top: 0;
}

.police-carousel .jcarousel ul li img {
  max-width: 100%;
  margin: 0 5px;
}

.police-carousel .fpMainLeftImg img {
  width: 100%;
  max-width: 100%;
  height: auto;
  overflow: hidden;
}

.recentArticles {
  float: right;
  margin-top: 10px;
}

.menuSection .recentArticles {
  float: left;
}

.recentArticles .header, .headTitle {
  text-transform: uppercase;
  color: #fff;
  background: red;
  margin-top: .5em;
  margin-bottom: 1em;
  padding-left: 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 2.3em;
  line-height: 1.2em;
  position: relative;
}

.recentArticles .header a, .headTitle a {
  color: #fff;
  display: block;
}

.recentArticles h4.header:after {
  content: "";
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -50px -900px no-repeat;
  width: 20px;
  height: 10px;
  position: absolute;
  top: 1.19em;
  left: 10%;
}

.recentArticles .item {
  border-bottom: 0 solid #cad5df;
  padding: 1em 0;
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0) !important;
  border-top: 0 !important;
}

.recentArticles.topArticles .item {
  border-bottom: 1px solid #cad5df;
}

.recentArticles .item.first {
  padding-top: 10px;
}

.recentArticles .item.last {
  border-bottom: 0;
}

.recentArticles .item .image {
  float: left;
  width: 25%;
  overflow: hidden;
}

.recentArticles .item .title {
  float: left;
  width: 67.1875%;
  margin-left: 10px;
  font-size: 1.2em;
  line-height: 1.2em;
}

.recentArticles.topArticles .item .title {
  width: 95%;
  margin: 0 5% 0 0;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
}

.recentArticles .item .title a {
  color: #16212d;
  font-size: 1.7em;
  font-weight: bold;
  line-height: 1.2em;
  transition: all .1s ease-out;
}

.recentArticles .item .image img {
  width: 100%;
  max-width: 100%;
}

.recentArticles .categoryArrow, .smallArticlePhotoLess .categoryArrow {
  width: 10px;
  height: 10px;
  margin-top: -5px;
  position: absolute;
  top: 50%;
  right: 0;
}

.recentArticles .categoryArrow .arrow, .smallArticlePhotoLess .categoryArrow .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -250px -216px no-repeat;
  width: 8px;
  height: 10px;
  display: block;
}

.article-container.middleArticle.tiles {
  height: 16em;
  padding-top: 5px;
}

.article-container.middleArticle.tiles .wrapper {
  height: 2em;
  padding-top: .5em;
  padding-left: 0;
}

.article-container.middleArticle.tiles h2.title, .article-container.middleArticle.tiles h3.title {
  top: 0;
}

.article-container.middleArticle.tiles img {
  width: 100%;
  top: 0;
  left: 0;
}

.article-container.middleArticle.tiles .articlePhoto {
  padding-top: 5px;
}

.article-container.middleArticle.tiles h2.title a, .article-container.middleArticle.tiles h3.title a {
  font-size: 1.6em;
  line-height: 1.2em;
}

.fb-like {
  text-align: center;
  margin: 2em;
  display: block;
}

.related {
  background: #fff;
  width: 96.875%;
  margin: 10px 0;
  padding: 10px;
  position: relative;
  left: -10px;
}

.related .nadpisRubriky {
  color: red;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 5px;
  padding: 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 3em;
  line-height: 1em;
}

.artContent .related .title {
  float: left;
  width: 100%;
  padding-left: 10px;
  font-size: .7em;
  line-height: 1.2em;
}

.artContent .related .nadpisRubriky {
  color: red;
  text-transform: uppercase;
  padding: 0 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.3em;
  line-height: 1.1em;
}

.artContent h3 .related .nadpisRubriky {
  font-size: .7em;
}

.related .relatedArticle {
  float: left;
  border-top: 1px solid #cad5df;
  width: 100%;
  padding: 10px 0;
}

.related .image {
  float: left;
  width: 25%;
}

.related .title {
  float: left;
  width: 70.3125%;
  padding-left: 10px;
  font-size: 1.7em;
  line-height: 1.2em;
}

.related .title a {
  color: #16212d;
  font-weight: bold;
}

.backToHome {
  clear: both;
  text-align: center;
  float: left;
  background: red;
  width: 100%;
  margin: 3em 0 0;
  padding: .7em 0;
  position: relative;
  top: -3em;
}

.backToHome a {
  color: #fff;
  text-transform: uppercase;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 2.5em;
  line-height: 1.2em;
}

.artContent h2 {
  float: left;
  width: 100%;
  margin: 20px 0 5px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 30px;
  line-height: 1.1em;
}

.artContent h3 {
  float: left;
  width: 100%;
  margin: 20px 0 5px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 24px;
  line-height: 1.2em;
  text-decoration: underline;
}

.artContent h3:hover {
  text-decoration: none;
}

.artContent h3 a {
  color: red;
  font-size: 20px;
  text-decoration: underline;
}

.artContent h3 a:hover {
  text-decoration: none;
}

.artContent h4 {
  margin: 10px 0 5px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 30px;
  line-height: 35px;
}

.displayNone {
  display: none;
}

.onlinePrenosNadpis {
  clear: both;
  border-top: 4px solid #16212d;
  margin: 1em 0 .5em;
  padding-top: .5em;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.5em;
  line-height: 1.2em;
}

.onlinePrenosNadpis a.refresh {
  float: right;
  text-indent: -9000px;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0 -1440px no-repeat;
  width: 94px;
  height: 30px;
  margin: 7px 0 0;
  display: inline-block;
}

.online_report .report_item {
  border-bottom: 1px solid #cad5df;
  padding: 1em 0;
}

.online_report .report_item .meta {
  float: left;
  width: 20%;
}

.online_report .report_item .time {
  font-weight: bold;
}

.online_report .report_item .text p {
  margin: 0 0 1em;
  padding: 0 0 .65em 25%;
}

.online_report .report_item .obrazekKomentatora {
  float: left;
  margin: 0 .5em .5em 0;
}

.online_report .report_item .media {
  float: left;
  width: 100%;
}

.online_report .report_item .media a {
  float: left;
  width: 40%;
  margin: 5%;
}

.online_report .report_item .media a img {
  width: 100%;
}

.online_report .media {
  float: none;
  background: none;
  width: 100%;
  display: block;
}

.online_report .media .reportImage {
  float: none;
  background: none;
  margin: 15px 0 0;
  display: block;
  width: 90% !important;
}

.online_report .media .reportImage img {
  width: 100%;
}

.tricolorBorderTop {
  border-top-width: 4px;
  border-image: url("https://img2.cncenter.cz/images/blesk-2013/extra/volby2013/tricolor.png") 4 repeat !important;
}

.onlineReportBox {
  background: #fff;
  border-top: 4px solid red;
  margin-top: 10px;
}

.onlineReportBox.fixHeight {
  padding-bottom: 10px;
  overflow: hidden;
}

.onlineReportBox.fixHeight .headerComments {
  color: #fff;
  text-transform: uppercase;
  float: left;
  text-align: center;
  background: red;
  border-top: none;
  min-width: 60px;
  margin-bottom: 0;
  padding-left: 0;
  font-family: tablet_gothic_compressed, sans-serif;
  font-size: 16px;
  line-height: 40px;
  display: block;
}

.onlineReportBox.fixHeight .title {
  color: #2a3440;
  float: left;
  text-transform: uppercase;
  padding-left: 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 40px;
}

.onlineReportBox .record.first {
  border-bottom: 1px solid #cad5df;
  margin: 10px 10px 0;
  padding: 0 0 19px;
}

.onlineReportBox .record {
  border-bottom: 1px solid #cad5df;
  margin: 0 10px;
  padding: 10px 0;
  font-size: 15px;
}

.onlineReportBox .question {
  padding-bottom: 10px;
}

.onlineReportBox .question .author {
  padding-left: 5px;
  font-size: 11px;
  font-weight: bold;
}

.onlineReportBox .live {
  color: #fff;
  text-transform: uppercase;
  background: red;
  padding: 5px 15px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 16px;
  position: absolute;
  top: 14px;
  right: 10px;
}

.onlineReportBox .live a {
  color: #fff;
}

.onlineReportBox .records {
  width: 100%;
  height: 350px;
  overflow-x: hidden;
  overflow-y: auto;
}

.onlineReportBox .record .text {
  color: #333;
  font-size: 15px;
  line-height: 17px;
}

.onlineReportBox .record .important {
  float: left;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -1150px 0 no-repeat;
  width: 23px;
  height: 23px;
  margin-top: 5px;
  margin-left: 9px;
}

.onlineReportBox .record .media.firstImage {
  float: left;
}

.onlineReportBox .record .media.rest {
  margin-top: 10px;
}

.onlineReportBox .record .media.rest img {
  float: left;
  margin: 0 2px;
}

.onlineReportBox .record .media.firstImage img {
  margin: 0 10px 10px 0;
}

.onlineReportBox .record .text em {
  background: url("https://img2.cncenter.cz/images/blesk-2013/quotes-bg.png?v=49") no-repeat;
  margin: 20px 0;
  padding: 20px 0 20px 30px;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
}

.onlineReportBox .record .answer .reply {
  text-align: left;
  margin-left: 0;
  margin-right: 0;
}

.onlineReportBox .footerReport {
  text-align: right;
  text-transform: uppercase;
  color: #2a3440;
  margin-right: 10px;
  padding: 0;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 40px;
}

.onlineReportBox .footerReport a {
  color: #2a3440;
  font-size: 16px;
}

.onlineReportBox .footerReport .arrow {
  float: right;
  width: 30px;
  height: 12px;
  margin-top: 14px;
  margin-left: 10px;
  display: block;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -250px -216px no-repeat !important;
}

.onlineReportBox .record .answer .text {
  float: none;
  width: 100%;
  margin-left: 0;
}

.onlineReportBox .record .time {
  color: red;
  text-align: left;
  padding: 5px 0 0;
  font-size: 15px;
  font-weight: bold;
}

.onlineReportBox .record .date {
  color: #16212d;
  text-align: left;
  padding: 5px 0 0;
  font-size: 12px;
  font-weight: normal;
  overflow: hidden;
}

.enquiry {
  width: 100%;
  margin: 1.5em 0;
}

.enquiry .question {
  border-top: 4px solid #16212d;
  margin: 1em 0 .5em;
  padding-top: .2em;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.5em;
  line-height: 1.2em;
  display: block;
}

.enquiry .countVotes {
  color: #848f9b;
  text-align: center;
  border-top: 2px solid #cad5df;
  border-bottom: 2px solid #cad5df;
  padding: .2em 0;
  font-size: 1em;
  line-height: 1.2em;
}

.enquiry ul {
  margin: 1em 0;
  padding: 0;
  list-style: none;
}

.enquiry ul li {
  padding: .2em 0 .4em;
}

.enquiry ul li a {
  color: #5c6773;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.5em;
  line-height: 1.2em;
}

.enquiry ul li a:hover {
  text-decoration: none;
}

.enquiry ul li a .answer {
  padding: 0;
}

.enquiry ul li a.option .value {
  display: none;
}

.enquiry ul li a.option .circle {
  float: left;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") -75px -400px no-repeat;
  width: 20px;
  height: 20px;
  margin-top: .3em;
  margin-right: .7em;
  display: block;
}

.enquiry ul li a:hover .circle {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") -75px -375px no-repeat;
}

.enquiry.voted ul li a.option .circle {
  display: none;
}

.enquiry.voted ul li a.option .value {
  float: left;
  width: 20%;
  display: block;
}

.enquiry.voted ul li a.option .graph {
  width: 80%;
  height: 4px;
  margin-top: .2em;
  margin-left: 20%;
  display: block;
}

.enquiry.voted ul li a.option .graph .graphValue {
  background-color: #5c6773;
  height: 4px;
  display: block;
}

.container .condolence {
  background: #16212d;
  margin-top: 10px;
  position: relative;
}

.container .condolence .title {
  color: #cad5df;
  text-transform: uppercase;
  margin: .3em;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.7em;
  line-height: 1.2em;
}

.container .condolence .image {
  width: 100%;
}

.container .condolence .description {
  color: #cad5df;
  margin: .3em;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.7em;
  line-height: 1.2em;
}

.container .condolence .addCondolence {
  color: #fff;
  float: right;
  text-transform: uppercase;
  background: #5c6773;
  margin: .5em;
  padding: .3em;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.5em;
  line-height: 1.2em;
  display: block;
}

.jcarousel ul {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
}

.jcarousel li {
  float: left;
}

.container .condolence .condolenceCarousel {
  border-top: 1px solid #48535f;
  border-bottom: 1px solid #48535f;
  height: 110px;
  margin-bottom: 10px;
  position: relative;
}

.container .condolence .condolenceCarousel a.jcarousel-prev {
  text-indent: -9999px;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -50px -216px no-repeat content-box content-box;
  top: 1em;
  left: 2.5%;
}

.container .condolence .condolenceCarousel a.jcarousel-next {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -50px -216px no-repeat content-box content-box;
  top: 1em;
  right: 2.5%;
}

a.jcarousel-prev {
  text-indent: -9999px;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -250px -216px no-repeat content-box content-box;
  width: 10px;
  height: 10px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 10px;
  display: block;
  position: absolute;
  top: 35px;
  left: 5px;
  transform: rotate(180deg);
}

a.jcarousel-next {
  text-indent: -9999px;
  background: #16212d url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -250px -216px no-repeat content-box content-box;
  width: 10px;
  height: 10px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 10px;
  display: block;
  position: absolute;
  top: 35px;
  right: 5px;
}

.container .condolence .enterSpecial {
  color: #fff;
  float: left;
  text-transform: uppercase;
  background: red;
  margin: .5em;
  padding: .3em;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.5em;
  line-height: 1.2em;
  display: block;
}

.container .condolence .jcarousel {
  float: left;
  width: 85%;
  height: 80px;
  margin: 1em 0;
  position: relative;
  left: 7.5%;
  overflow: hidden;
}

.container .condolence .jcarousel li {
  color: #cad5df;
  height: 80px;
  margin: 0;
  font-family: arial, sans-serif;
  font-size: 1.3em;
  line-height: 1.2em;
}

.container .condolence .jcarousel li .from {
  color: #848f9b;
  padding-left: 5px;
}

.condolenceCount, #article .topGallery .condolenceCount {
  color: #cad5df;
  background: #16212d;
  padding: 20px 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 36px;
  display: block;
}

.condolenceCount {
  display: none;
}

.condolenceList ul li.even {
  background: #fff;
}

.condolenceList ul li {
  color: #16212d;
  background: #fff;
  margin-bottom: .8em;
  padding: 2%;
  font-size: .6em;
  line-height: 1.2em;
}

#article .artContent .condolenceList ul li p {
  margin: 0;
  font-size: 1em;
  font-weight: normal;
  line-height: 1.2em;
}

#article .artContent .condolenceTalk input {
  color: #98a3af;
  float: left;
  background: #fff;
  border: none;
  width: 46%;
  margin: 10px 0;
  padding: 2%;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: normal;
}

.condolenceList ul li .from {
  color: #98a3af;
  padding-left: 7px;
}

#article .artContent .condolenceTalk .darkBtn {
  color: #fff;
  text-transform: uppercase;
  float: none;
  background: #5c6773;
  border: none;
  width: 96%;
  padding: 2%;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.1em;
  line-height: 1.2em;
  display: block;
}

#article .artContent .condolenceTalk h3 {
  text-transform: uppercase;
  border-top: 4px solid #16212d;
  padding-top: 10px;
  font-size: 1.6em;
  line-height: 1.1em;
}

#frm-ot .colorBtn, #frm-ot .lightBtn, #frm-ot .darkBtn {
  float: none;
  width: 30%;
  margin: .2em auto;
  font-size: 1.6em;
  display: block;
}

#frm-ot textarea {
  color: #98a3af;
  background: #fff;
  border: none;
  width: 96%;
  height: 88px;
  padding: 2%;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: normal;
}

.condolenceList ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.container .greetings {
  background: red;
  margin-top: 10px;
  position: relative;
}

.container .greetings .title {
  color: #fff;
  text-transform: uppercase;
  margin: .3em;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.7em;
  line-height: 1.2em;
}

.container .greetings .image {
  width: 100%;
}

.container .greetings .description {
  color: #fff;
  margin: .3em;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.7em;
  line-height: 1.2em;
}

.container .greetings .addgreetings {
  color: #fff;
  float: right;
  text-transform: uppercase;
  background: #540101;
  margin: .5em;
  padding: .3em;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.5em;
  line-height: 1.2em;
  display: block;
}

.container .greetings .greetingsCarousel {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  height: 110px;
  margin-bottom: 0;
  position: relative;
}

.container .greetings .greetingsCarousel a.jcarousel-prev {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -250px -232px no-repeat content-box content-box;
  top: 1em;
  left: 2.5%;
}

.container .greetings .greetingsCarousel a.jcarousel-next {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -250px -232px no-repeat content-box content-box;
  top: 1em;
  right: 2.5%;
}

.container .greetings .enterSpecial {
  color: red;
  float: left;
  text-transform: uppercase;
  background: #fff;
  margin: .5em;
  padding: .3em;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.5em;
  line-height: 1.2em;
  display: block;
}

.container .greetings .jcarousel {
  width: 85%;
  height: 80px;
  margin: 10px 0;
  position: relative;
  left: 7.5%;
  overflow: hidden;
}

.container .greetings .jcarousel li {
  color: #fff;
  width: 100%;
  height: 80px;
  margin: 0;
  font-family: arial, sans-serif;
  font-size: 1.3em;
  line-height: 1.2em;
}

.container .greetings .jcarousel li .from {
  color: #eee;
  padding-left: 5px;
}

.greetingsCount, #article .topGallery .greetingsCount {
  color: #fff;
  background: #16212d;
  padding: 20px 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 36px;
  display: block;
}

.greetingsCount {
  display: none;
}

.greetingsList ul li {
  color: #16212d;
  background: #fff;
  margin-bottom: .8em;
  padding: 2%;
  font-size: .6em;
  line-height: 1.2em;
}

#article .artContent .greetingsList ul li p {
  margin: 0;
  font-size: 1em;
  line-height: 1.2em;
}

#article .artContent .greetingsTalk input {
  color: #98a3af;
  float: left;
  background: #fff;
  border: none;
  width: 46%;
  margin: 10px 0;
  padding: 2%;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: normal;
}

.greetingsList ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.greetingsList ul li .from {
  color: #98a3af;
  padding-left: 7px;
}

#article .artContent .greetingsTalk .darkBtn {
  color: #fff;
  text-transform: uppercase;
  float: right;
  background: red;
  border: none;
  width: 96%;
  padding: 2%;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.1em;
  line-height: 1.2em;
  display: block;
}

#article .artContent .greetingsTalk h3 {
  text-transform: uppercase;
  color: red;
  border-top: 4px solid red;
  padding-top: 10px;
  font-size: 1.6em;
  line-height: 1.1em;
}

.loadMore {
  justify-content: center;
  height: 40px;
  margin: 24px 0;
  display: flex;
}

.loadMore a.load, .loadMore button.load {
  letter-spacing: .18px;
  cursor: pointer;
  background-color: red;
  border: none;
  justify-content: center;
  align-items: center;
  padding: 12px 30px 7px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  display: flex;
  color: #fff !important;
}

.loadMoreArt {
  clear: both;
  float: left;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  height: 30px;
  margin: 5px auto;
  padding-top: 12px;
  font-weight: normal;
  line-height: 30px;
  text-decoration: none;
  display: block;
  position: relative;
  bottom: 0;
  left: 0;
}

.loadMoreArt a {
  cursor: pointer;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1.7em;
  color: #98a3af !important;
}

.loadMore a.load:hover {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -277px -1100px no-repeat;
}

.galleryInArticle {
  background: #16212d;
  padding: 10px;
}

.galleryInArticle .header a {
  float: left;
  text-transform: uppercase;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.2em;
}

.galleryInArticle .header .galleryCount {
  float: right;
  color: #e9eff4;
  font-size: .7em;
  line-height: 1.2em;
  display: inline-block;
}

.galleryInArticle .bigPhoto .photoIn {
  width: 100%;
  margin: .5em 0;
  display: inline-block;
  position: relative;
}

.galleryInArticle .bigPhoto .photoIn img {
  width: 100%;
  max-width: 100%;
}

.galleryInArticle .description {
  color: #e9eff4;
  padding: .5em 0;
  font-size: .8em;
  line-height: 1.2em;
}

.galleryInArticle .imgAuthor {
  color: #98a3af;
  margin: 0;
  font-size: .7em;
  line-height: 1.2em;
}

.smallArticlePhotoLess {
  background: none;
  border-top: 2px solid red;
  padding: 1em 0 0;
}

.smallArticlePhotoLess .title {
  float: left;
  width: 90%;
  margin: 0;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 2em;
}

.container .smallArticlePhotoLess {
  border-top: 0;
  border-bottom: 1px solid #cad5df;
  padding: 0 0 .5em;
}

.container .smallArticlePhotoLess .title a, .container .article-container.smallArticlePhotoLess .title a {
  color: #16212d !important;
}

.smallArticlePhotoLess .title a {
  color: #16212d;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.2em;
  transition: all .1s ease-out;
}

.wikiResult .wrapper .articlePhoto {
  float: left;
  max-width: 46%;
  max-height: 10em;
  top: .5em;
}

.wikiResult .wrapper .articlePhoto img {
  max-height: 9em;
}

@media (max-width: 640px) {
  .wikiResult .wrapper .articlePhoto img {
    min-height: 19.5vw;
  }
}

.searchText {
  color: #16212d;
  margin: .5em 0;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 2em;
}

.onlineTalkDiv .chatRecords .record {
  background: #fff;
  margin: 1em 0;
  padding: 1em;
  font-size: 1em;
}

.onlineTalkDiv .chatRecords .record .question {
  border-bottom: 1px solid #16212d;
  padding-bottom: 1em;
}

.onlineTalkDiv .chatRecords .record .question .text .uzivatel {
  text-align: right;
  padding-left: 0;
  font-weight: bold;
  display: block;
}

.onlineTalkDiv .chatRecords .record .answer {
  padding: 1em 0;
}

.onlineTalkDiv .chatRecords .record .answer .obrazek {
  float: left;
  margin: 0 .5em .5em 0;
}

.onlineTalkDiv .chatRecords .record .answer .text {
  margin-left: 2.5em;
}

.article-container, .sectionHeader {
  border-top-color: red !important;
}

.article-container .articlePhoto {
  display: block;
  position: relative;
}

.article-container .articlePhoto a {
  display: block;
}

.alternative .sectionHeader, .alternative .article-container {
  border-top-color: #540101 !important;
}

.color, a.color, a.hoverColor:hover, .article-container:hover a.hoverColor {
  color: red !important;
}

.bg {
  background-color: red !important;
}

.alternative .article-container {
  color: red !important;
  background-color: red !important;
}

.alternative .color, .alternative a.color, .alternative .article-container a {
  color: #fff !important;
}

.alternative a.hoverColor:hover, .alternative .color.persist, .article-container:hover .color.persist, .alternative .article-container:hover a.hoverColor {
  color: #540101 !important;
}

.recentArticles .categoryArrow .arrow, .article-container p.perex:before {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") -300px -450px no-repeat !important;
}

.alternative .article-container p.perex:before {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0 -900px no-repeat !important;
}

.alternative .sectionHeader li a.dropdownMenu:after {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

.iconPlay {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -250px -150px no-repeat !important;
}

.domino .article-container, .domino .sectionHeader, .domino .smallArticlePhotoLess, .domino .alternative .article-container, .domino .alternative .sectionHeader, .domino.alternative .article-container, .domino.alternative .sectionHeader {
  border-top-color: #ff5a00 !important;
}

.domino .color, .domino a.color, .domino a.hoverColor:hover, .domino .article-container:hover a.hoverColor, .domino .smallArticlePhotoLess .title a {
  color: #ff5a00 !important;
}

.domino .bg {
  background-color: #ff5a00 !important;
}

.domino.alternative .article-container {
  color: #8b7c5e !important;
  background-color: #ff5a00 !important;
}

.domino.alternative .color, .domino.alternative a.color, .domino .alternative .color, .domino .alternative a.color, .domino .alternative .article-container a, .domino.alternative .article-container a {
  color: #fff !important;
}

.domino.alternative a.hoverColor:hover, .domino.alternative .color.persist, .domino .alternative a.hoverColor:hover, .domino .alternative .color.persist, .domino .article-container:hover .color.persist, .domino .alternative .article-container:hover a.hoverColor, .domino.alternative .article-container:hover a.hoverColor {
  color: #ff5a00 !important;
}

.domino .article-container.bigOneArticle .title .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px 0 no-repeat !important;
}

.domino .recentArticles .categoryArrow .arrow, .domino .article-container p.perex:before, .domino .smallArticlePhotoLess .categoryArrow .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") -300px -350px no-repeat !important;
}

.domino.alternative .article-container p.perex:before, .domino .alternative .article-container p.perex:before {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0 -900px no-repeat !important;
}

.domino .sectionHeader li a.dropdownMenu:after {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0 -200px no-repeat !important;
}

.domino.alternative .sectionHeader li a.dropdownMenu:after, .domino .alternative .sectionHeader li a.dropdownMenu:after {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

.domino .iconPlay {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0 -150px no-repeat !important;
}

.domino .article-container.middleArticle h2.title a:hover, .domino .article-container.middleArticle h2.title a.hoverColor:hover, .domino .article-container.middleArticle h3.title a:hover, .domino .article-container.middleArticle h3.title a.hoverColor:hover {
  color: #ff5a00 !important;
}

.hotpink .article-container, .hotpink .sectionHeader, .hotpink .smallArticlePhotoLess {
  border-top-color: #fe60b2 !important;
}

.hotpink .alternative .article-container, .hotpink .alternative .sectionHeader, .hotpink.alternative .article-container, .hotpink.alternative .sectionHeader {
  border-top-color: #6d0139 !important;
}

.hotpink .color, .hotpink a.color, .hotpink a.hoverColor:hover, .hotpink .article-container:hover a.hoverColor, .hotpink .smallArticlePhotoLess .title a {
  color: #fe60b2 !important;
}

.hotpink .bg {
  background-color: #fe60b2 !important;
}

.hotpink.alternative .article-container {
  color: #fe60b2 !important;
  background-color: #fe60b2 !important;
}

.hotpink.alternative .color, .hotpink.alternative a.color, .hotpink .alternative .color, .hotpink .alternative a.color, .hotpink .alternative .article-container a, .hotpink.alternative .article-container a {
  color: #fff !important;
}

.hotpink.alternative a.hoverColor:hover, .hotpink.alternative .color.persist, .hotpink .alternative a.hoverColor:hover, .hotpink .alternative .color.persist, .hotpink .article-container:hover .color.persist, .hotpink .alternative .article-container:hover a.hoverColor, .hotpink.alternative .article-container:hover a.hoverColor {
  color: #6d0139 !important;
}

.hotpink .article-container.bigOneArticle .title .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -120px no-repeat !important;
}

.hotpink .recentArticles .categoryArrow .arrow, .hotpink .article-container p.perex:before, .hotpink .smallArticlePhotoLess .categoryArrow .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") -300px -430px no-repeat !important;
}

.hotpink.alternative .article-container p.perex:before, .hotpink .alternative .article-container p.perex:before {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0 -900px no-repeat !important;
}

.hotpink .sectionHeader li a.dropdownMenu:after {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -200px -200px no-repeat !important;
}

.hotpink.alternative .sectionHeader li a.dropdownMenu:after, .hotpink .alternative .sectionHeader li a.dropdownMenu:after {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

.hotpink .iconPlay {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -200px -150px no-repeat !important;
}

.hotpink .article-container.middleArticle h2.title a:hover, .colortesty .article-container.middleArticle h2.title a.hoverColor:hover, .hotpink .article-container.middleArticle h3.title a:hover, .colortesty .article-container.middleArticle h3.title a.hoverColor:hover {
  color: #fe60b2 !important;
}

.chateau .article-container, .chateau .sectionHeader, .chateau .smallArticlePhotoLess {
  border-top-color: #99a4aa !important;
}

.chateau .alternative .article-container, .chateau .alternative .sectionHeader, .chateau.alternative .article-container, .chateau.alternative .sectionHeader {
  border-top-color: #424e56 !important;
}

.chateau .color, .chateau a.color, .chateau a.hoverColor:hover, .chateau .article-container:hover a.hoverColor, .chateau .smallArticlePhotoLess .title a {
  color: #99a4aa !important;
}

.chateau.alternative .article-container {
  color: #99a4aa !important;
  background-color: #99a4aa !important;
}

.chateau.alternative .color, .chateau.alternative a.color, .chateau .alternative .color, .chateau .alternative a.color, .chateau .alternative .article-container a, .chateau.alternative .article-container a {
  color: #fff !important;
}

.chateau.alternative a.hoverColor:hover, .chateau.alternative .color.persist, .chateau .alternative a.hoverColor:hover, .chateau .alternative .color.persist, .chateau .article-container:hover .color.persist, .chateau .alternative .article-container:hover a.hoverColor, .chateau.alternative .article-container:hover a.hoverColor {
  color: #424e56 !important;
}

.chateau .article-container.bigOneArticle .title .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -30px no-repeat !important;
}

.chateau .recentArticles .categoryArrow .arrow, .chateau .article-container p.perex:before, .chateau .smallArticlePhotoLess .categoryArrow .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") -300px -370px no-repeat !important;
}

.chateau.alternative .article-container p.perex:before, .chateau .alternative .article-container p.perex:before {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0 -900px no-repeat !important;
}

.chateau .sectionHeader li a.dropdownMenu:after {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -50px -200px no-repeat !important;
}

.chateau.alternative .sectionHeader li a.dropdownMenu:after, .chateau .alternative .sectionHeader li a.dropdownMenu:after {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

.chateau .iconPlay {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -50px -150px no-repeat !important;
}

.chateau .article-container.middleArticle h2.title a:hover, .colortesty .article-container.middleArticle h2.title a.hoverColor:hover, .chateau .article-container.middleArticle h3.title a:hover, .colortesty .article-container.middleArticle h3.title a.hoverColor:hover {
  color: #99a4aa !important;
}

.scooter .article-container, .scooter .sectionHeader, .scooter .smallArticlePhotoLess {
  border-top-color: #3cc3dc !important;
}

.scooter .alternative .article-container, .scooter .alternative .sectionHeader, .scooter.alternative .article-container, .scooter.alternative .sectionHeader {
  border-top-color: #004754 !important;
}

.scooter .color, .scooter a.color, .scooter a.hoverColor:hover, .scooter .article-container:hover a.hoverColor, .scooter .smallArticlePhotoLess .title a {
  color: #3cc3dc !important;
}

.scooter .bg {
  background-color: #3cc3dc !important;
}

.scooter.alternative .article-container {
  color: #3cc3dc !important;
  background-color: #3cc3dc !important;
}

.scooter.alternative .color, .scooter.alternative a.color, .scooter .alternative .color, .scooter .alternative a.color, .scooter .alternative .article-container a, .scooter.alternative .article-container a {
  color: #fff !important;
}

.scooter.alternative a.hoverColor:hover, .scooter.alternative .color.persist, .scooter .alternative a.hoverColor:hover, .scooter .alternative .color.persist, .scooter .article-container:hover .color.persist, .scooter .alternative .article-container:hover a.hoverColor, .scooter.alternative .article-container:hover a.hoverColor {
  color: #004754 !important;
}

.scooter .article-container.bigOneArticle .title .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -300px no-repeat !important;
}

.scooter .recentArticles .categoryArrow .arrow, .scooter .article-container p.perex:before, .scooter .smallArticlePhotoLess .categoryArrow .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") -300px -550px no-repeat !important;
}

.scooter.alternative .article-container p.perex:before, .scooter .alternative .article-container p.perex:before {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0 -900px no-repeat !important;
}

.scooter .sectionHeader li a.dropdownMenu:after {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -500px -200px no-repeat !important;
}

.scooter.alternative .sectionHeader li a.dropdownMenu:after, .scooter .alternative .sectionHeader li a.dropdownMenu:after {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

.scooter .iconPlay {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -500px -150px no-repeat !important;
}

.scooter .article-container.middleArticle h2.title a:hover, .colortesty .article-container.middleArticle h2.title a.hoverColor:hover, .scooter .article-container.middleArticle h3.title a:hover, .colortesty .article-container.middleArticle h3.title a.hoverColor:hover {
  color: #3cc3dc !important;
}

.heliotrope .article-container, .heliotrope .sectionHeader, .heliotrope .smallArticlePhotoLess {
  border-top-color: #b469f5 !important;
}

.heliotrope .alternative .article-container, .heliotrope .alternative .sectionHeader, .heliotrope.alternative .article-container, .heliotrope.alternative .sectionHeader {
  border-top-color: #3a016b !important;
}

.heliotrope .color, .heliotrope a.color, .heliotrope a.hoverColor:hover, .heliotrope .article-container:hover a.hoverColor, .heliotrope .smallArticlePhotoLess .title a {
  color: #b469f5 !important;
}

.heliotrope .bg {
  background-color: #b469f5 !important;
}

.heliotrope.alternative .article-container {
  color: #b469f5 !important;
  background-color: #b469f5 !important;
}

.heliotrope.alternative .color, .heliotrope.alternative a.color, .heliotrope .alternative .color, .heliotrope .alternative a.color, .heliotrope .alternative .article-container a, .heliotrope.alternative .article-container a {
  color: #fff !important;
}

.heliotrope.alternative a.hoverColor:hover, .heliotrope.alternative .color.persist, .heliotrope .alternative a.hoverColor:hover, .heliotrope .alternative .color.persist, .heliotrope .article-container:hover .color.persist, .heliotrope .alternative .article-container:hover a.hoverColor, .heliotrope.alternative .article-container:hover a.hoverColor {
  color: #3a016b !important;
}

.heliotrope .article-container.bigOneArticle .title .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -90px no-repeat !important;
}

.heliotrope .recentArticles .categoryArrow .arrow, .heliotrope .article-container p.perex:before, .heliotrope .smallArticlePhotoLess .categoryArrow .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -150px -216px no-repeat !important;
}

.heliotrope.alternative .article-container p.perex:before, .heliotrope .alternative .article-container p.perex:before {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0 -900px no-repeat !important;
}

.heliotrope .sectionHeader li a.dropdownMenu:after {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -150px -200px no-repeat !important;
}

.heliotrope.alternative .sectionHeader li a.dropdownMenu:after, .heliotrope .alternative .sectionHeader li a.dropdownMenu:after {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

.heliotrope .iconPlay {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -150px -150px no-repeat !important;
}

.heliotrope .article-container.middleArticle h2.title a:hover, .colortesty .article-container.middleArticle h2.title a.hoverColor:hover, .heliotrope .article-container.middleArticle h3.title a:hover, .colortesty .article-container.middleArticle h3.title a.hoverColor:hover {
  color: #b469f5 !important;
}

.robinblue .article-container, .robinblue .sectionHeader, .robinblue .smallArticlePhotoLess {
  border-top-color: #00c8be !important;
}

.robinblue .alternative .article-container, .robinblue .alternative .sectionHeader, .robinblue.alternative .article-container, .robinblue.alternative .sectionHeader {
  border-top-color: #004d49 !important;
}

.robinblue .color, .robinblue a.color, .robinblue a.hoverColor:hover, .robinblue .article-container:hover a.hoverColor, .robinblue .smallArticlePhotoLess .title a {
  color: #00c8be !important;
}

.robinblue .bg {
  background-color: #00c8be !important;
}

.robinblue.alternative .article-container {
  color: #00c8be !important;
  background-color: #00c8be !important;
}

.robinblue.alternative .color, .robinblue.alternative a.color, .robinblue .alternative .color, .robinblue .alternative a.color, .robinblue .alternative .article-container a, .robinblue.alternative .article-container a {
  color: #fff !important;
}

.robinblue.alternative a.hoverColor:hover, .robinblue.alternative .color.persist, .robinblue .alternative a.hoverColor:hover, .robinblue .alternative .color.persist, .robinblue .article-container:hover .color.persist, .robinblue .alternative .article-container:hover a.hoverColor, .robinblue.alternative .article-container:hover a.hoverColor {
  color: #004d49 !important;
}

.robinblue .article-container.bigOneArticle .title .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -270px no-repeat !important;
}

.robinblue .recentArticles .categoryArrow .arrow, .robinblue .article-container p.perex:before, .robinblue .smallArticlePhotoLess .categoryArrow .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") -300px -530px no-repeat !important;
}

.robinblue.alternative .article-container p.perex:before, .robinblue .alternative .article-container p.perex:before {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0 -900px no-repeat !important;
}

.robinblue .sectionHeader li a.dropdownMenu:after {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -450px -200px no-repeat !important;
}

.robinblue.alternative .sectionHeader li a.dropdownMenu:after, .robinblue .alternative .sectionHeader li a.dropdownMenu:after {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

.robinblue .iconPlay {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -450px -150px no-repeat !important;
}

.robinblue .article-container.middleArticle h2.title a:hover, .colortesty .article-container.middleArticle h2.title a.hoverColor:hover, .robinblue .article-container.middleArticle h3.title a:hover, .colortesty .article-container.middleArticle h3.title a.hoverColor:hover {
  color: #00c8be !important;
}

.chetwode .article-container, .chetwode .sectionHeader, .chetwode .smallArticlePhotoLess {
  border-top-color: #6e78dc !important;
}

.chetwode .alternative .article-container, .chetwode .alternative .sectionHeader, .chetwode.alternative .article-container, .chetwode.alternative .sectionHeader {
  border-top-color: #191e56 !important;
}

.chetwode .color, .chetwode a.color, .chetwode a.hoverColor:hover, .chetwode .article-container:hover a.hoverColor, .chetwode .smallArticlePhotoLess .title a {
  color: #6e78dc !important;
}

.chetwode .bg {
  background-color: #6e78dc !important;
}

.chetwode.alternative .article-container {
  color: #6e78dc !important;
  background-color: #6e78dc !important;
}

.chetwode.alternative .color, .chetwode.alternative a.color, .chetwode .alternative .color, .chetwode .alternative a.color, .chetwode .alternative .article-container a, .chetwode.alternative .article-container a {
  color: #fff !important;
}

.chetwode.alternative a.hoverColor:hover, .chetwode.alternative .color.persist, .chetwode .alternative a.hoverColor:hover, .chetwode .alternative .color.persist, .chetwode .article-container:hover .color.persist, .chetwode .alternative .article-container:hover a.hoverColor, .chetwode.alternative .article-container:hover a.hoverColor {
  color: #191e56 !important;
}

.chetwode .article-container.bigOneArticle .title .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -360px no-repeat !important;
}

.chetwode .recentArticles .categoryArrow .arrow, .chetwode .article-container p.perex:before, .chetwode .smallArticlePhotoLess .categoryArrow .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -600px -216px no-repeat !important;
}

.chetwode.alternative .article-container p.perex:before, .chetwode .alternative .article-container p.perex:before {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0 -900px no-repeat !important;
}

.chetwode .sectionHeader li a.dropdownMenu:after {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -600px -200px no-repeat !important;
}

.chetwode.alternative .sectionHeader li a.dropdownMenu:after, .chetwode .alternative .sectionHeader li a.dropdownMenu:after {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

.chetwode .iconPlay {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -600px -150px no-repeat !important;
}

.chetwode .article-container.middleArticle h2.title a:hover, .colortesty .article-container.middleArticle h2.title a.hoverColor:hover, .chetwode .article-container.middleArticle h3.title a:hover, .colortesty .article-container.middleArticle h3.title a.hoverColor:hover {
  color: #6e78dc !important;
}

.jade .article-container, .jade .sectionHeader, .jade .smallArticlePhotoLess {
  border-top-color: #00b373 !important;
}

.jade .alternative .article-container, .jade .alternative .sectionHeader, .jade.alternative .article-container, .jade.alternative .sectionHeader {
  border-top-color: #00482e !important;
}

.jade .color, .jade a.color, .jade a.hoverColor:hover, .jade .article-container:hover a.hoverColor, .jade .smallArticlePhotoLess .title a {
  color: #00b373 !important;
}

.jade .bg {
  background-color: #00b373 !important;
}

.jade.alternative .article-container {
  color: #00b373 !important;
  background-color: #00b373 !important;
}

.jade.alternative .color, .jade.alternative a.color, .jade .alternative .color, .jade .alternative a.color, .jade .alternative .article-container a, .jade.alternative .article-container a {
  color: #fff !important;
}

.jade.alternative a.hoverColor:hover, .jade.alternative .color.persist, .jade .alternative a.hoverColor:hover, .jade .alternative .color.persist, .jade .article-container:hover .color.persist, .jade .alternative .article-container:hover a.hoverColor, .jade.alternative .article-container:hover a.hoverColor {
  color: #00482e !important;
}

.jade .article-container.bigOneArticle .title .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -240px no-repeat !important;
}

.jade .recentArticles .categoryArrow .arrow, .jade .article-container p.perex:before, .jade .smallArticlePhotoLess .categoryArrow .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -400px -216px no-repeat !important;
}

.jade.alternative .article-container p.perex:before, .jade .alternative .article-container p.perex:before {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0 -900px no-repeat !important;
}

.jade .sectionHeader li a.dropdownMenu:after {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -400px -200px no-repeat !important;
}

.jade.alternative .sectionHeader li a.dropdownMenu:after, .jade .alternative .sectionHeader li a.dropdownMenu:after {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

.jade .iconPlay {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -400px -150px no-repeat !important;
}

.jade .article-container.middleArticle h2.title a:hover, .colortesty .article-container.middleArticle h2.title a.hoverColor:hover, .jade .article-container.middleArticle h3.title a:hover, .colortesty .article-container.middleArticle h3.title a.hoverColor:hover {
  color: #00b373 !important;
}

.homeorange .article-container, .homeorange .sectionHeader, .homeorange .smallArticlePhotoLess {
  border-top-color: #ff7300 !important;
}

.homeorange .alternative .article-container, .homeorange .alternative .sectionHeader, .homeorange.alternative .article-container, .homeorange.alternative .sectionHeader {
  border-top-color: #00482e !important;
}

.homeorange .color, .homeorange a.color, .homeorange a.hoverColor:hover, .homeorange .article-container:hover a.hoverColor, .homeorange .smallArticlePhotoLess .title a {
  color: #ff7300 !important;
}

.homeorange .bg {
  background-color: #ff7300 !important;
}

.homeorange.alternative .article-container {
  color: #ff7300 !important;
  background-color: #ff7300 !important;
}

.homeorange.alternative .color, .homeorange.alternative a.color, .homeorange .alternative .color, .homeorange .alternative a.color, .homeorange .alternative .article-container a, .homeorange.alternative .article-container a {
  color: #fff !important;
}

.homeorange.alternative a.hoverColor:hover, .homeorange.alternative .color.persist, .homeorange .alternative a.hoverColor:hover, .homeorange .alternative .color.persist, .homeorange .article-container:hover .color.persist, .homeorange .alternative .article-container:hover a.hoverColor, .homeorange.alternative .article-container:hover a.hoverColor {
  color: #00482e !important;
}

.homeorange .article-container.bigOneArticle .title .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -240px no-repeat !important;
}

.homeorange .recentArticles .categoryArrow .arrow, .homeorange .article-container p.perex:before, .homeorange .smallArticlePhotoLess .categoryArrow .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -300px -216px no-repeat !important;
}

.homeorange .iconPlay {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -300px -150px no-repeat !important;
}

.homeorange .article-container.middleArticle h2.title a:hover, .colortesty .article-container.middleArticle h2.title a.hoverColor:hover, .homeorange .article-container.middleArticle h3.title a:hover, .colortesty .article-container.middleArticle h3.title a.hoverColor:hover {
  color: #ff7300 !important;
}

.saffron .article-container, .saffron .sectionHeader, .saffron .smallArticlePhotoLess {
  border-top-color: #f7b135 !important;
}

.saffron .alternative .article-container, .saffron .alternative .sectionHeader, .saffron.alternative .article-container, .saffron.alternative .sectionHeader {
  border-top-color: #694506 !important;
}

.saffron .color, .saffron a.color, .saffron a.hoverColor:hover, .saffron .article-container:hover a.hoverColor, .saffron .smallArticlePhotoLess .title a {
  color: #f7b135 !important;
}

.saffron .bg {
  background-color: #f7b135 !important;
}

.saffron.alternative .article-container {
  color: #f7b135 !important;
  background-color: #f7b135 !important;
}

.saffron.alternative .color, .saffron.alternative a.color, .saffron .alternative .color, .saffron .alternative a.color, .saffron .alternative .article-container a, .saffron.alternative .article-container a {
  color: #fff !important;
}

.saffron.alternative a.hoverColor:hover, .saffron.alternative .color.persist, .saffron .alternative a.hoverColor:hover, .saffron .alternative .color.persist, .saffron .article-container:hover .color.persist, .saffron .alternative .article-container:hover a.hoverColor, .saffron.alternative .article-container:hover a.hoverColor {
  color: #694506 !important;
}

.saffron .article-container.bigOneArticle .title .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -180px no-repeat !important;
}

.saffron .recentArticles .categoryArrow .arrow, .saffron .article-container p.perex:before, .saffron .smallArticlePhotoLess .categoryArrow .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -300px -217px no-repeat !important;
}

.saffron.alternative .article-container p.perex:before, .saffron .alternative .article-container p.perex:before {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0 -900px no-repeat !important;
}

.saffron .sectionHeader li a.dropdownMenu:after {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -300px -200px no-repeat !important;
}

.saffron.alternative .sectionHeader li a.dropdownMenu:after, .saffron .alternative .sectionHeader li a.dropdownMenu:after {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

.saffron .iconPlay {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -300px -150px no-repeat !important;
}

.saffron .article-container.middleArticle h2.title a:hover, .colortesty .article-container.middleArticle h2.title a.hoverColor:hover, .saffron .article-container.middleArticle h3.title a:hover, .colortesty .article-container.middleArticle h3.title a.hoverColor:hover {
  color: #694506 !important;
}

.atlantis .article-container, .atlantis .sectionHeader, .atlantis .smallArticlePhotoLess {
  border-top-color: #91c837 !important;
}

.atlantis .alternative .article-container, .atlantis .alternative .sectionHeader, .atlantis.alternative .article-container, .atlantis.alternative .sectionHeader {
  border-top-color: #355401 !important;
}

.atlantis .color, .atlantis a.color, .atlantis a.hoverColor:hover, .atlantis .article-container:hover a.hoverColor, .atlantis .smallArticlePhotoLess .title a, .atlantis.list-article__category {
  color: #91c837 !important;
}

.atlantis .bg {
  background-color: #91c837 !important;
}

.atlantis.alternative .article-container {
  color: #91c837 !important;
  background-color: #91c837 !important;
}

.atlantis.alternative .color, .atlantis.alternative a.color, .atlantis .alternative .color, .atlantis .alternative a.color, .atlantis .alternative .article-container a, .atlantis.alternative .article-container a {
  color: #fff !important;
}

.atlantis.alternative a.hoverColor:hover, .atlantis.alternative .color.persist, .atlantis .alternative a.hoverColor:hover, .atlantis .alternative .color.persist, .atlantis .article-container:hover .color.persist, .atlantis .alternative .article-container:hover a.hoverColor, .atlantis.alternative .article-container:hover a.hoverColor {
  color: #355401 !important;
}

.atlantis .article-container.bigOneArticle .title .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -210px no-repeat !important;
}

.atlantis .recentArticles .categoryArrow .arrow, .atlantis .article-container p.perex:before, .atlantis .smallArticlePhotoLess .categoryArrow .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") -300px -490px no-repeat !important;
}

.atlantis.alternative .article-container p.perex:before, .atlantis .alternative .article-container p.perex:before {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0 -900px no-repeat !important;
}

.atlantis .sectionHeader li a.dropdownMenu:after {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -350px -200px no-repeat !important;
}

.atlantis.alternative .sectionHeader li a.dropdownMenu:after, .atlantis .alternative .sectionHeader li a.dropdownMenu:after {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

.atlantis .iconPlay {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -350px -150px no-repeat !important;
}

.atlantis .article-container.middleArticle h2.title a:hover, .colortesty .article-container.middleArticle h2.title a.hoverColor:hover, .atlantis .article-container.middleArticle h3.title a:hover, .colortesty .article-container.middleArticle h3.title a.hoverColor:hover {
  color: #91c837 !important;
}

.colortesty .article-container, .colortesty .sectionHeader, .colortesty .smallArticlePhotoLess {
  border-top-color: #d42a34 !important;
}

.colortesty .alternative .article-container, .colortesty .alternative .sectionHeader, .colortesty.alternative .article-container, .colortesty.alternative .sectionHeader {
  border-top-color: #355401 !important;
}

.colortesty .color, .colortesty a.color, .colortesty a.hoverColor:hover, .colortesty .article-container:hover a.hoverColor, .colortesty .smallArticlePhotoLess .title a {
  color: #d42a34 !important;
}

.colortesty .bg {
  background-color: #d42a34 !important;
}

.colortesty.alternative .article-container {
  color: #d42a34 !important;
  background-color: #d42a34 !important;
}

.colortesty.alternative .color, .colortesty.alternative a.color, .colortesty .alternative .color, .colortesty .alternative a.color, .colortesty .alternative .article-container a, .colortesty.alternative .article-container a {
  color: #fff !important;
}

.colortesty.alternative a.hoverColor:hover, .colortesty.alternative .color.persist, .colortesty .alternative a.hoverColor:hover, .colortesty .alternative .color.persist, .colortesty .article-container:hover .color.persist, .colortesty .alternative .article-container:hover a.hoverColor, .colortesty.alternative .article-container:hover a.hoverColor {
  color: #355401 !important;
}

.colortesty .article-container.bigOneArticle .title .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -210px no-repeat !important;
}

.colortesty .recentArticles .categoryArrow .arrow, .colortesty .article-container p.perex:before, .colortesty .smallArticlePhotoLess .categoryArrow .arrow {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-mobile-webp.webp?v=16") -300px -490px no-repeat !important;
}

.colortesty.alternative .article-container p.perex:before, .colortesty .alternative .article-container p.perex:before {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") 0 -900px no-repeat !important;
}

.colortesty .sectionHeader li a.dropdownMenu:after {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -350px -200px no-repeat !important;
}

.colortesty.alternative .sectionHeader li a.dropdownMenu:after, .colortesty .alternative .sectionHeader li a.dropdownMenu:after {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -100px -900px no-repeat !important;
}

.colortesty .iconPlay {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -350px -150px no-repeat !important;
}

.colortesty .article-container.middleArticle h2.title a:hover, .colortesty .article-container.middleArticle h2.title a.hoverColor:hover, .colortesty .article-container.middleArticle h3.title a:hover, .colortesty .article-container.middleArticle h3.title a.hoverColor:hover {
  color: #d42a34 !important;
}

.top3Soci-pic img {
  max-height: 250px;
}

.videoportalVertical {
  color: #fff;
  padding: 10px;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 120%;
}

.videoportalVertical a {
  color: #fff;
  font-size: 16px;
}

.videoportalVertical hr {
  border: none;
  border-top: 1px solid #ddd;
  height: 1px;
}

.hlavickaVideoPortal h2 {
  color: #fff;
  margin-top: 0;
  font-size: 20px;
}

.videoFirstTitle {
  height: 18%;
  margin-top: 10px;
  font-size: 16px;
}

.videoFirstTitle a {
  font-size: 16px;
}

.hlavickaVideoPortal {
  color: #ddd;
  font-size: 16px;
}

.lesk .videoportalVertical {
  background: #2c538d;
}

.bz .videoportalVertical {
  background: #c42a35;
}

.tabik {
  margin-left: 10px;
}

.lesk .outer_4 {
  width: 48.4%;
  margin: 0 0 0 1.6%;
}

.bz .outer_4 {
  width: 48.4%;
  margin: 0;
}

.numOfWatch {
  margin-top: 10px;
  font-family: arial, sans-serif;
  font-size: 12px;
  display: none;
  text-align: right !important;
}

.tvBleskBanner {
  color: #fff;
  background: #000;
  padding: 5px 0;
  font-size: 25px;
}

.tvBleskBanner a, .tvBleskBanner a:hover {
  color: #fff;
}

.nextTvBlesk {
  color: #ddd;
  float: right;
  margin: 10px 10px 0 0;
  font-size: 20px;
  display: inline-block;
}

.bz .iconPlay {
  width: 38px;
  height: 38px;
  display: block;
  position: absolute;
  top: 35%;
  left: 40%;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -1050px -1399px no-repeat !important;
}

.lesk .iconPlay {
  width: 38px;
  height: 38px;
  display: block;
  position: absolute;
  top: 35%;
  left: 40%;
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -1000px -1399px no-repeat !important;
}

.article-container.middleArticle .icon {
  width: 38px;
  height: 38px;
  display: block;
}

.article-container.middleArticle .iconWrapper, .article-container.bigArticle .iconWrapper {
  position: relative;
}

.mobileAdFix {
  float: none;
  margin: 20px auto 0;
  display: block;
  position: relative;
}

.calendarContainer .nameDay {
  font-size: .8em !important;
}

.chatArticle.chatArticleScroll {
  height: 328px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.chatArticle .report-link, .chatArticle .button-main {
  font-size: 15px;
}

.inner_8 .horoskop-box {
  background-color: #fff;
  border-top: 4px solid #7f32eb;
  border-bottom: 1px solid #7f32eb;
  width: 100%;
  display: block;
  position: relative;
}

.inner_8 .horoskop-box img {
  margin-left: 4.5%;
}

.horoskop-violet-bg {
  background-color: #7f32eb;
  width: 68%;
  height: 100%;
  position: absolute;
  right: 0;
}

.box-title1 {
  color: #fff;
  width: auto;
  height: auto;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 2.8em;
  font-weight: normal;
  position: absolute;
  top: 5%;
  left: 35%;
}

.box-title2 {
  color: #fff;
  width: auto;
  height: auto;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 2.4em;
  position: absolute;
  bottom: 5%;
  left: 35%;
}

.white-arrow {
  position: absolute;
  top: 40%;
  right: 1%;
}

.chateau .recentArticles .item .title {
  width: 65%;
  margin-left: 16px;
  font-family: tablet_gothic_condensed, sans-serif;
  font-size: 1.3em;
}

.chateau .recentArticles .item .image {
  width: 22%;
}

.chateau .recentArticles h4.header {
  height: 48px;
  padding: 0 auto;
  font-size: 16px;
  line-height: 16px;
  text-align: left !important;
  background-color: #0087c8 !important;
  padding-left: 0 !important;
}

.chateau .recentArticles h4.header:after {
  content: none;
}

.chateau .bg {
  background-color: #0087c8 !important;
}

.chateau .recentArticles h4.header .denik {
  text-indent: -9999px;
  background: url("https://img2.cncenter.cz/images/blesk-2013/logo-denik-mobile.png?v=3") 0 0 no-repeat;
  width: 200px;
  height: 38px;
  display: block;
  position: relative;
  top: 5px;
  left: 0;
}

.article-container.bigArticle .icon, .article-container.middleArticle .icon {
  z-index: 1;
  margin: auto;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.icon-play-small {
  background-position: -347px -524px;
  width: 18px;
  height: 19px;
}

.icon-play_icon_big {
  background-position: -569px -397px;
  width: 42px;
  height: 41px;
}

.icon-play_icon_small {
  background-position: -679px -566px;
  width: 29px;
  height: 28px;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height .5s ease-in-out;
}

.owl-carousel {
  z-index: 1;
  width: 100%;
  display: none;
  position: relative;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .owl-stage {
  touch-action: pan-y;
  position: relative;
}

.owl-carousel .owl-stage:after {
  content: ".";
  clear: both;
  visibility: hidden;
  height: 0;
  line-height: 0;
  display: block;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-controls .owl-nav .owl-prev, .owl-carousel .owl-controls .owl-nav .owl-next, .owl-carousel .owl-controls .owl-dot {
  cursor: hand;
  -webkit-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  float: left;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-user-select: none;
  user-select: none;
  min-height: 1px;
  position: relative;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s;
}

.owl-carousel .owl-item img {
  transform-style: preserve-3d;
  width: 100%;
  display: block;
}

.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  user-select: auto;
}

.owl-carousel .owl-grab {
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .owl-video-wrapper {
  background: #000;
  height: 100%;
  position: relative;
}

.owl-carousel .owl-video-play-icon {
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: url("owl.video.play.png") no-repeat;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  transition: scale .1s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.owl-carousel .owl-video-play-icon:hover {
  transition: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn, .owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  transition: opacity .4s;
}

.owl-carousel .owl-video-frame {
  z-index: 1;
  position: relative;
}

.no-js .owl-carousel {
  display: block;
}

.gallery-main-container {
  background-color: #000;
  width: 100%;
  height: 100%;
  position: relative;
}

.gallery-main-container:after, .gallery-main-container:before {
  content: "";
  clear: both;
  display: table;
}

.gallery-main-container .gallery {
  width: 100%;
  height: 100%;
}

.gallery-main-container .gallery .icon-loading {
  width: 35px;
  height: 35px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .images-container {
  width: 100%;
  height: 100%;
}

.gallery-main-container .gallery .images-container .owl-item {
  height: 100%;
}

.gallery-main-container .gallery .images-container .owl-item img {
  width: auto;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.gallery-main-container .gallery .images-container .owl-stage-outer, .gallery-main-container .gallery .images-container .owl-stage-outer .owl-stage, .gallery-main-container .gallery .images-container .owl-stage-outer .owl-stage .owl-item {
  height: 100%;
}

.gallery-main-container .gallery .btn {
  z-index: 50;
  cursor: pointer;
  background: rgba(0, 0, 0, .6);
  width: 9%;
  height: 100%;
  display: block;
  position: absolute;
}

.gallery-main-container .gallery .btn .icon-arrow {
  width: 13px;
  height: 26px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .next {
  right: 0;
}

.gallery-main-container .gallery .next .image-count {
  text-align: center;
  color: #fff;
  margin: auto;
  font-size: 26px;
  font-weight: 300;
  position: absolute;
  bottom: 5px;
  left: 4%;
  right: 4%;
}

.gallery-main-container .image-description {
  color: #aaa;
  padding: 20px 0;
}

.gallery-main-container .image-description div {
  display: inline-block;
}

.gallery-main-container .image-description .description {
  padding: 0 5px 0 0;
}

.gallery-main-container .image-description .author {
  font-style: italic;
}

#article .box-gallery .image-description, #article .box-gallery .image-count {
  display: none;
}

#article .box-gallery {
  width: 100%;
  height: 328px;
  position: relative;
}

.box-gallery .gallery-main-container .gallery .btn.prev .dataimg-arrow-light-left {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -950px -450px no-repeat;
}

.box-gallery .gallery-main-container .gallery .btn.next .dataimg-arrow-light-right {
  background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -1000px -450px no-repeat;
}

article .article-gallery-container {
  background-color: #fff;
  margin: 0 0 20px;
  padding: 20px 10px 20px 20px;
}

.gallery-strip {
  width: 100%;
  margin: 20px 0;
}

.gallery-strip:after, .gallery-strip:before {
  content: "";
  clear: both;
  display: table;
}

.gallery-strip .gallery-margin {
  margin-top: 35px;
}

.gallery-strip a.image {
  float: left;
  width: 110px;
  margin: 0 18px 0 0;
  display: inline-block;
}

.gallery-strip a.image img {
  width: 100%;
  height: auto;
}

.gallery-strip a.text-container {
  margin-top: 7px;
}

.gallery-strip a.additional-value-container .additional-value {
  float: left;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-weight: bold;
  display: block;
}

.gallery-strip a.additional-value-container .additional-value strong {
  font-size: 13px;
}

.gallery-items {
  background: #fff;
  height: 62px;
  margin: 0;
  font-size: 12px;
  position: relative;
  overflow: hidden;
}

.gallery-items ul {
  height: 60px;
  max-height: 60px;
  margin: 0;
  padding: 1px;
  list-style-type: none;
  transition: all .3s;
  position: relative;
  overflow: hidden;
}

.gallery-items ul li {
  display: inline-block;
}

.gallery-items ul li a {
  color: #1e1e1e;
  text-align: center;
  height: 66px;
  display: inline-block;
}

.gallery-items ul li a.hover {
  background-color: rgba(255, 255, 255, .08);
}

.gallery-items ul li:last-child {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0, #fff 4em);
  padding-left: 5em;
  position: absolute;
  bottom: -5px;
  right: 10px;
}

.gallery-items ul li:last-child a {
  color: #16212d;
  background: #fff;
  margin-right: -5px;
  padding: 0;
}

.gallery-items ul li:last-child a:hover {
  text-decoration: none;
}

.gallery-items ul li:last-child a .additional-description {
  margin-left: 5px;
}

.gallery-container {
  background-color: #1e1e1e;
  height: 100%;
}

.gallery-container .gallery-container {
  width: 1050px;
  height: 620px;
  margin: 35px auto 60px;
}

@media screen and (max-width: 760px) {
  .gallery-container .gallery-container {
    width: 100%;
    height: 350px;
    margin-bottom: 60px;
  }

  .gallery-container .image-description {
    padding: 15px;
  }

  .gallery-main-container .gallery .btn {
    width: 50px;
    top: 0;
  }

  .gallery-main-container .gallery .next .image-count {
    font-size: .8em;
  }
}

a.additional-value-container .additional-value {
  float: left;
  clear: both;
  color: #16212d;
  font-size: 30px;
  line-height: 33px;
}

a.additional-value-container .additional-description {
  float: left;
  clear: both;
}

.media-icons-wrapper {
  background: rgba(152, 163, 175, .75);
  width: 17px;
  height: 15px;
  padding: 6px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.media-icons-wrapper .icon.play {
  width: 15px;
  height: 15px;
}

.container .wrapper-matches .box-container .list-score-link .list-score .match-container .team-container .logo-container {
  float: right;
  width: 10%;
}

.container .wrapper-matches .box-container .list-score-link .list-score .match-container .team-container.team-away .logo-container {
  float: left;
}

.breadcrumbs {
  margin: 15px;
  position: relative;
  overflow: hidden;
}

.breadcrumbs ol {
  width: 100%;
  height: auto;
  padding: 0;
  position: relative;
}

.breadcrumbs li {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 400px;
  margin: 0 7px 0 0;
  line-height: 15px;
  display: inline-block;
  overflow: hidden;
}

.breadcrumbs li a {
  color: #aaa;
}

.breadcrumbs li span {
  color: #aaa;
  font-size: 13px;
}

.breadcrumbs li:last-child span {
  font-weight: bold;
}

.breadcrumbs .arrow-icon {
  width: 5px;
  height: 9px;
  margin: 2px 7px 0 0;
  display: inline-block;
  position: relative;
  top: -3px;
}

.breadcrumbs .arrow-icon:last-child {
  display: none;
}

.breadcrumbs .dataimg-arrow-right {
  background-image: url("data:image/svg+xml;charset=US-ASCII,<svg%20viewBox%3D'0%200%205%209'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20%20><path%20fill%3D'%23AAAAAA'%20d%3D'M5%2C4.3L1.3%2C0L0%2C0.7l3.1%2C3.7L0%2C8.2L1.3%2C9L5%2C4.6V4.3z'%2F><%2Fsvg>");
  background-repeat: no-repeat;
}

#main-promobox .shadowDown {
  display: none;
}

.obalka-bydleni {
  background: #fff;
  width: 100%;
  min-height: 149px;
  margin: 10px auto 0;
  display: block;
}

.obalka-bydleni .edition-magazine {
  padding: 15px;
}

.obalka-bydleni .linkImage {
  vertical-align: middle;
  display: table-cell;
}

.obalka-bydleni .linkImage a {
  background: #fff;
  width: 100px;
  height: 149px;
  margin: 0 30px 0 0;
  display: block;
}

.obalka-bydleni a img {
  width: 100%;
  height: 100%;
  display: block;
}

.obalka-bydleni .linkText {
  vertical-align: middle;
  width: 100%;
  padding-right: 20px;
  display: table-cell;
  position: relative;
}

.obalka-bydleni .linkText:before {
  content: "";
  border: 7px solid rgba(0, 0, 0, 0);
  border-left: 10px solid #16212d;
  border-right-width: 10px;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  top: calc(50% - 7px);
  right: -4px;
}

.obalka-bydleni .linkText a {
  color: #16212d;
  font-family: tablet_gothic_condensed, arial, Helvetica, sans-serif;
  font-size: 26px;
  line-height: 29px;
}

.obalka-bydleni .linkText a span {
  display: none;
}

@media (max-width: 640px) {
  .article__tags {
    padding-left: 15px;
  }
}

.share-strip {
  z-index: 10001;
  width: 100%;
  height: 40px;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
}

.share-strip .share {
  text-align: center;
  cursor: pointer;
  float: left;
  display: none;
}

.share-strip .share.facebook {
  background-color: #4868ba;
}

.share-strip .share.twitter {
  background-color: #5db3e6;
}

.share-strip .share.whatsapp {
  background-color: #00c956;
}

.share-strip .share.e-mail {
  background-color: rgba(255, 255, 0, 0);
}

.share-strip .share.e-mail .icone {
  height: 20px;
  margin: 10px auto;
}

.share-strip .share.viber {
  background-color: #7d3daf;
}

.share-strip .share.messenger {
  background-color: #0084ff;
}

.share-strip .share .icone {
  height: 24px;
  margin: 8px auto;
}

.dataimg-email-icon {
  background-image: url("https://img2.cncenter.cz/images/blesk/share-strip/email-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.dataimg-facebook-white-2 {
  background-image: url("https://img2.cncenter.cz/images/blesk/share-strip/facebook-white-2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.dataimg-messenger {
  background-image: url("https://img2.cncenter.cz/images/blesk/share-strip/messenger.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.dataimg-twitter-white {
  background-image: url("https://img2.cncenter.cz/images/blesk/share-strip/twitter-white.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.dataimg-whatsapp {
  background-image: url("https://img2.cncenter.cz/images/blesk/share-strip/whatsapp.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.denniVyhra {
  border: 0;
  width: 100%;
  overflow: auto;
}

.denniVyhra thead tr {
  color: #fff;
  background-color: #5c6773;
  font-size: 11px;
  font-weight: bold;
}

.denniVyhra thead tr td {
  vertical-align: middle;
  width: auto;
}

.denniVyhra tbody tr {
  color: #16212d;
  background-color: #e9eff4;
  font-size: 10px;
}

@media only screen and (max-width: 426px) {
  .denniVyhra tbody tr {
    font-size: 11px;
  }
}

@media only screen and (max-width: 376px) {
  .denniVyhra {
    width: 319px;
  }

  .denniVyhra tbody tr {
    font-size: 9px;
  }
}

.denniVyhra td {
  border: 0;
  width: auto;
}

.denniVyhra td:first-child {
  width: 58px;
  padding-left: 1px;
  padding-right: 1px;
}

.denniVyhra td:nth-child(2) {
  width: auto;
}

.denniVyhra tbody tr td {
  padding: 8px 2px 7px;
}

.denniVyhra td:not(:last-child) {
  border-right: 1px solid #98a3af;
}

.denniVyhra tbody tr:nth-child(odd) {
  background-color: #fff;
}

.main-article .content .article-gallery {
  float: none;
}

.article-gallery {
  color: #323232;
  float: left;
  clear: both;
  background: #f1f1f1;
  margin-bottom: 25px;
  padding: 25px 0;
}

.article-gallery .title {
  font-family: Open Sans, arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

.article-gallery .gallery-title {
  color: #ee1c25;
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 22px;
  display: inline-block;
}

.article-gallery .gallery-main-container {
  height: inherit;
  margin-bottom: 20px;
}

.article-gallery .gallery-main-container .image-description {
  padding: 10px;
  font-size: 16px;
}

.article-gallery .photo-count {
  float: right;
  font-size: 16px;
  line-height: 22px;
  display: inline-block;
}

.article-gallery .list-gallery {
  margin-top: 0;
  position: relative;
}

.article-gallery .list-gallery .dataimg-arrow-light-left {
  float: left;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 35px;
  left: -25px;
}

.article-gallery .list-gallery .dataimg-arrow-light-left svg path {
  fill: #323232;
}

.article-gallery .list-gallery .dataimg-arrow-light-right {
  float: right;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 35px;
  right: -25px;
}

.article-gallery .list-gallery .dataimg-arrow-light-right svg path {
  fill: #323232;
}

.article-gallery .list-gallery .gallery-item {
  float: left;
  margin: 3px;
}

.article-gallery .list-gallery .gallery-item.first {
  margin-left: 0;
}

.article-gallery .list-gallery .gallery-item.last {
  margin-right: 0;
}

.relative {
  position: relative;
}

@media screen and (max-width: 768px) {
  .magazine-links {
    flex-direction: column;
    margin-bottom: 0;
  }

  #footer-global .magazine-links__stores {
    margin: 0 48px 40px;
  }

  .magazine-links__stores-google {
    margin-right: 24px;
  }
}

.sc-gLLuof.fRdbir h2 {
  color: #191919;
  border-bottom: 1px solid #e7e7e7;
  font-family: Vafle Classic, arial, Helvetica, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.sc-gLLuof.fRdbir label {
  gap: 0;
  flex-direction: column !important;
}

.sc-gLLuof.fRdbir label .eycUNu {
  color: #888;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.sc-gLLuof.fRdbir label .keVjxN {
  padding: 4px 12px 0 0;
}

.sc-gLLuof.fRdbir label .keVjxN input {
  background: #fff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.sc-gLLuof.fRdbir label .keVjxN textarea {
  background: #fff;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  flex-direction: column;
  height: 100px;
  min-height: 100px;
  padding: 12px;
  display: flex;
}

.sc-gLLuof.fRdbir label.leimcV .keVjxN {
  clear: both;
  padding: 0 12px 0 0;
  position: relative;
  top: -25px;
}

.sc-gLLuof.fRdbir label.leimcV .keVjxN .ciEuJM {
  padding: 5px 0 0 29px;
}

.sc-gLLuof.fRdbir label.leimcV .eycUNu {
  color: #191919;
  z-index: 10;
  padding: 0 0 0 30px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
}

.sc-gLLuof.fRdbir label.leimcV .eycUNu a {
  text-decoration: underline;
}

@media screen and (max-width: 520px) {
  .sc-gLLuof.fRdbir label.leimcV {
    height: 100px;
    position: relative;
  }

  .sc-gLLuof.fRdbir label.leimcV .keVjxN {
    height: 100px;
    position: absolute;
    top: 4px;
  }

  .sc-gLLuof.fRdbir label.leimcV .keVjxN .ciEuJM {
    margin-top: 35px;
  }
}

@media screen and (max-width: 320px) {
  .sc-gLLuof.fRdbir label.leimcV, .sc-gLLuof.fRdbir label.leimcV .keVjxN {
    height: 130px;
  }

  .sc-gLLuof.fRdbir label.leimcV .keVjxN .ciEuJM {
    margin-top: 60px;
  }
}

.wikiResult .article-container {
  padding: 0;
}

.wikiResult .article-container.middleArticle {
  height: auto;
  padding: 0;
}

.wikiResult .article-container.middleArticle .wrapper {
  flex-direction: row;
  gap: 10px;
  height: auto;
  margin-top: 0;
  display: flex;
  overflow: visible;
}

.wikiResult .article-container.middleArticle .wrapper .articlePhoto {
  top: 0;
}

.wikiResult .article-container.middleArticle h3.title {
  margin: 0;
}

.wikiResult .article-container.middleArticle h3.title a {
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  width: -webkit-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  max-height: 80px;
  margin: 0;
  padding: 10px 0;
  line-height: 115%;
  display: -webkit-box;
  overflow: hidden;
}

@media screen and (max-width: 375px) {
  .wikiResult .article-container.middleArticle h3.title a {
    -webkit-line-clamp: 3;
    box-sizing: border-box;
    max-height: 72px;
  }
}

